import { AccountField } from '../control-panel/account/accountField';
import { AddressField, ClientAddressField } from '../control-panel/client/addresses/clientAddressFields';
import { AssortmentField } from '../control-panel/assortment/assortmentField';
import { AssortmentOperationField } from '../storage/assortment-operation/assortmentOperationField';
import { AttachmentField } from './attachments/attachmentField';
import { ClientField } from '../control-panel/client/clientField';
import { ClientAssortmentField } from '../control-panel/client/assortment/clientAssortmentField';
import { ContractField } from '../control-panel/client/contracts/clientContractField';
import { CrmUserField } from '../control-panel/crmUser/crmUserField';
import { PalletPassportField } from '../runs/talman/palletPassportField';
import { ProductionLineField } from '../control-panel/productionLine/productionLineField';
import { QuoteField } from '../quotes/quoteField';
import { RunField } from '../runs/runField';
import { TmcField } from '../control-panel/tmc/tmcField';
import { TmcOperationField } from '../storage/tmc-operation/tmcOperationField';
import { VehicleField } from '../control-panel/vehicle/vehicleField';
import { RunExpenseField } from '../runs/expenses/runExpenseField';
import { RouteField } from '../runs/route/routeField';
import { ShipmentDetailField } from '../quotes/shipment-details/shipmentDetailField';

export interface TableData {
    table: TableInfo;
    fields: FieldInfo[];
}

export interface TableInfo {
    latin: string;
    cyrillic?: string;
    parentDependentCyrillic?: Record<string, string>;
}

export interface FieldInfo {
    latin: string[];
    cyrillic: string;
}

export const findFieldByLatin = (tableData: TableData, latinName: string): FieldInfo | undefined => {
    return tableData.fields.find(f => f.latin?.includes(latinName));
};

const idField: FieldInfo = {
    latin: ['id'],
    cyrillic: 'Идентификатор'
};

const phoneField: FieldInfo = {
    latin: ['phone'],
    cyrillic: 'Телефон'
};

const emailField: FieldInfo = {
    latin: ['email'],
    cyrillic: 'E-mail'
};

const commentField: FieldInfo = {
    latin: ['comment', 'description'],
    cyrillic: 'Комментарий'
};

const descriptionField: FieldInfo = {
    latin: ['description'],
    cyrillic: 'Дополнительно'
};

const fieldToLabelMapping: Record<string, TableData> = {
    account: {
        table: { latin: 'account', cyrillic: 'Расчетный счет' },
        fields: [
            idField,
            { latin: ['correspondent_number', AccountField.CORRESPONDENT_NUMBER], cyrillic: 'Корр. счет' },
            { latin: ['bank'], cyrillic: 'Банк' },
            { latin: ['number'], cyrillic: 'Р/Счет' },
            { latin: ['bik'], cyrillic: 'БИК' }
        ]
    },
    address: {
        table: { latin: 'address', cyrillic: 'Адрес' },
        fields: [
            idField,
            { latin: ['city', AddressField.CITY], cyrillic: 'Город (населенный пункт)' },
            { latin: ['longitude', AddressField.LONGITUDE], cyrillic: 'Долгота' },
            { latin: ['latitude', AddressField.LATITUDE], cyrillic: 'Широта' },
            { latin: ['region_iso', AddressField.REGION_ISO], cyrillic: 'ISO код региона' },
            { latin: ['street', AddressField.STREET], cyrillic: 'Адрес' },
            { latin: ['country_iso', AddressField.COUNTRY_ISO], cyrillic: 'ISO код страны' },
            { latin: ['fias_id', AddressField.FIAS_ID], cyrillic: 'ФИАС' }
        ]
    },
    assortment: {
        table: { latin: 'assortment', cyrillic: 'Номенклатура' },
        fields: [
            idField,
            { latin: ['value', AssortmentField.VALUE], cyrillic: 'Наименование' },
            { latin: ['quantity', AssortmentField.QUANTITY], cyrillic: 'Количество' },
            { latin: ['active', AssortmentField.ACTIVE], cyrillic: 'Активен' },
            { latin: ['unit_id', AssortmentField.UNIT], cyrillic: 'Единица измерения' },
            { latin: ['grade_id', AssortmentField.GRADE], cyrillic: 'Сорт' },
            { latin: ['nds_mode_id', AssortmentField.NDS_MODE], cyrillic: 'НДС' },
            { latin: ['type_id', AssortmentField.TYPE], cyrillic: 'Вид номенклатуры' },
        ]
    },
    assortment_operation: {
        table: { latin: 'assortment_operation', cyrillic: 'Операции Номенклатуры' },
        fields: [
            idField,
            commentField,
            { latin: ['assortment_id', AssortmentOperationField.ASSORTMENT], cyrillic: 'Номенклатура' },
            { latin: ['category_id', AssortmentOperationField.CATEGORY], cyrillic: 'Категория' },
            { latin: ['type_id', AssortmentOperationField.TYPE], cyrillic: 'Операция' },
            { latin: ['storage_from_id', AssortmentOperationField.STORAGE_FROM], cyrillic: 'Откуда (склад)' },
            { latin: ['storage_to_id', AssortmentOperationField.STORAGE_TO], cyrillic: 'Куда (склад)' },
            { latin: ['quote_id', AssortmentOperationField.QUOTE], cyrillic: 'Заказ' },
            { latin: ['dt', AssortmentOperationField.DT], cyrillic: 'Дата операции' },
            { latin: ['quantity', AssortmentOperationField.QUANTITY], cyrillic: 'Количество' },
            { latin: ['weight', AssortmentOperationField.WEIGHT], cyrillic: 'Вес' },
            { latin: ['run_id', AssortmentOperationField.RUN], cyrillic: 'Рейс' },
            { latin: ['client_address_from_id', AssortmentOperationField.CLIENT_ADDRESS_FROM], cyrillic: 'Откуда (адрес)' },
            { latin: ['client_address_to_id', AssortmentOperationField.CLIENT_ADDRESS_TO], cyrillic: 'Куда (адрес)' },
            { latin: ['assortment_to_id', AssortmentOperationField.ASSORTMENT_TO], cyrillic: 'Номенклатура назначения' },
        ]
    },
    attachment: {
        table: { latin: 'attachment', cyrillic: 'Прикрепленный файл' },
        fields: [
            idField,
            { latin: ['kind_id', AttachmentField.KIND], cyrillic: 'Вид файла' },
            { latin: ['created', AttachmentField.CREATED], cyrillic: 'Дата' },
            { latin: ['file_name', AttachmentField.FILE_NAME], cyrillic: 'Имя файла' },
            { latin: ['created_by', AttachmentField.CREATED_BY], cyrillic: 'ФИО прикрепившего' },
            { latin: ['subtype_id', AttachmentField.SUBTYPE], cyrillic: 'Подтип файла' }
        ]
    },
    client: {
        table: { latin: 'client', cyrillic: 'Контрагент' },
        fields: [
            idField,
            phoneField,
            emailField,
            commentField,
            { latin: ['currency_control', ClientField.CURRENCY_CONTROL], cyrillic: 'Подлежит валютному контролю' },
            { latin: ['type_id', ClientField.TYPE], cyrillic: 'Тип' },
            { latin: ['kind_id', ClientField.KIND], cyrillic: 'Вид' },
            { latin: ['company_id', ClientField.COMPANY], cyrillic: 'Организация' },
            { latin: ['logistic_manager_id', ClientField.LOGISTIC_MANAGER], cyrillic: 'Менеджер ОЛ' },
            { latin: ['commercial_manager_id', ClientField.COMMERCIAL_MANAGER], cyrillic: 'Менеджер КО' },
            { latin: ['active', ClientField.ACTIVE], cyrillic: 'Активен' },
            { latin: ['accredited', ClientField.ACCREDITED], cyrillic: 'Аккредитован' },
            { latin: ['alien', ClientField.ALIEN], cyrillic: 'Контрагент не РФ' },
            { latin: ['alien_inn', ClientField.ALIEN_INN], cyrillic: 'ИНН не РФ' },
            { latin: ['status_id', ClientField.STATUS], cyrillic: 'Статус' },
            { latin: ['name', ClientField.NAME], cyrillic: 'Краткое наименование' },
            { latin: ['full_name', ClientField.FULL_NAME], cyrillic: 'Полное наименование' },
            { latin: ['inn', ClientField.INN], cyrillic: 'ИНН' },
            { latin: ['kpp', ClientField.KPP], cyrillic: 'КПП' },
            { latin: ['ogrn', ClientField.OGRN], cyrillic: 'ОГРН' },
            { latin: ['web', ClientField.WEB], cyrillic: 'Сайт компании' },
            { latin: ['postal_address', ClientField.POSTAL_ADDRESS], cyrillic: 'Почтовый адрес' },
            { latin: ['actual_address', ClientField.ACTUAL_ADDRESS], cyrillic: 'Фактический адрес' },
            { latin: ['address', ClientField.ADDRESS], cyrillic: 'Юридический адрес' },
            { latin: ['head', ClientField.HEAD], cyrillic: 'ФИО руководителя' },
            { latin: ['head_position', ClientField.HEAD_POSITION], cyrillic: 'Должность руководителя' },
            { latin: ['head_genitive', ClientField.HEAD_GENITIVE], cyrillic: 'В лице' },
            { latin: ['ati_rating', ClientField.ATI_RATING], cyrillic: 'Рейтинг' },
            { latin: ['ati_code', ClientField.ATI_CODE], cyrillic: 'Код АТИ' },
            { latin: ['basis', ClientField.BASIS], cyrillic: 'Действует на основании' }
        ]
    },
    client_address: {
        table: { latin: 'client_address', parentDependentCyrillic: {
                'client': 'Адрес контрагента',
                'client_address': 'Склад'
            }},
        fields: [
            idField,
            { latin: ['address_id', ClientAddressField.ADDRESS_ID], cyrillic: 'Город и адрес' },
            { latin: ['type_id', ClientAddressField.TYPE], cyrillic: 'Тип' },
            { latin: ['specified_address', ClientAddressField.SPECIFIED_ADDRESS], cyrillic: 'Уточненный адрес / комментарий' },
            { latin: ['longitude', ClientAddressField.LONGITUDE], cyrillic: 'Долгота' },
            { latin: ['latitude', ClientAddressField.LATITUDE], cyrillic: 'Широта' },
            { latin: ['cadastral_number', ClientAddressField.CADASTRAL_NUMBER], cyrillic: 'Кадастровый номер' },
            { latin: ['name', ClientAddressField.NAME], cyrillic: 'Название' },
        ]
    },
    client_assortment: {
        table: { latin: 'client_assortment', cyrillic: 'Чек-лист' },
        fields: [
            idField,
            commentField,
            { latin: ['assortment_id', ClientAssortmentField.ASSORTMENT_ID], cyrillic: 'Наименование склада' },
            { latin: ['tare_tmc_id', ClientAssortmentField.TARE_TMC], cyrillic: 'Тара' },
            { latin: ['name', ClientAssortmentField.NAME], cyrillic: 'Наименование контрагента' },
            { latin: ['client_name', ClientAssortmentField.CLIENT_NAME], cyrillic: 'Наименование контрагента' },
            { latin: ['weight_min', ClientAssortmentField.WEIGHT_MIN], cyrillic: 'Квант' },
            { latin: ['weight_avg', ClientAssortmentField.WEIGHT_AVG], cyrillic: 'Вес ед.' },
            { latin: ['number', ClientAssortmentField.NUMBER], cyrillic: 'Артикул' },
            { latin: ['unit_id', ClientAssortmentField.UNIT], cyrillic: 'Единица измерения' },
            { latin: ['temperature_regime', ClientAssortmentField.TEMPERATURE_REGIME], cyrillic: 'Температурный режим' },
            { latin: ['package_tmc_id', ClientAssortmentField.PACKAGE_TMC], cyrillic: 'Упаковка' },
            { latin: ['weight_from', ClientAssortmentField.WEIGHT_FROM], cyrillic: 'Начальный калибр' },
            { latin: ['weight_to', ClientAssortmentField.WEIGHT_TO], cyrillic: 'Конечный калибр' },
            { latin: ['boxes', ClientAssortmentField.BOXES], cyrillic: 'Упаковка/Тара' },
            { latin: ['use_passport', ClientAssortmentField.USE_PASSPORT], cyrillic: 'Паспорт паллет' },
            { latin: ['use_net', ClientAssortmentField.USE_NET], cyrillic: 'Сетка' },
            { latin: ['use_talman', ClientAssortmentField.USE_TALMAN], cyrillic: 'Тальманская расписка' },
            { latin: ['unit_barcode', ClientAssortmentField.UNIT_BARCODE], cyrillic: 'Штрих-код единицы' },
            { latin: ['package_barcode', ClientAssortmentField.PACKAGE_BARCODE], cyrillic: 'Штрих-код упаковки' },
        ]
    },
    client_consignee: {
        table: { latin: 'client_consignee', cyrillic: 'Грузополучатель' },
        fields: [
            { latin: ['consignee_id'], cyrillic: 'Грузополучатель' }
        ]
    },
    contract: {
        table: { latin: 'contract', cyrillic: 'Договор' },
        fields: [
            idField,
            { latin: ['type_id', ContractField.TYPE], cyrillic: 'Вид договора' },
            { latin: ['status_id', ContractField.STATUS], cyrillic: 'Статус договора' },
            { latin: ['company_id', ContractField.COMPANY], cyrillic: 'Компания' },
            { latin: ['client_id', ContractField.CLIENT], cyrillic: 'Контрагент' },
            { latin: ['signed', ContractField.SIGNED], cyrillic: 'Дата договора' },
            { latin: ['begin_date', ContractField.BEGIN_DATE], cyrillic: 'Действует с' },
            { latin: ['end_date', ContractField.END_DATE], cyrillic: 'Действует до' },
            { latin: ['main', ContractField.MAIN], cyrillic: 'Основной' },
            { latin: ['has_additional', ContractField.HAS_ADDITIONAL], cyrillic: 'Есть дополнительное соглашение' },
            { latin: ['payment_delay', ContractField.PAYMENT_DELAY], cyrillic: 'Отсрочка платежа, дней' },
            { latin: ['delay_type_id', ContractField.DELAY_TYPE], cyrillic: 'Тип дней отсрочки' },
            { latin: ['payment_type_id', ContractField.PAYMENT_TYPE], cyrillic: 'Вид оплаты' },
            { latin: ['payment_method_id', ContractField.PAYMENT_METHOD], cyrillic: 'Форма оплаты' },
            { latin: ['ttn_days', ContractField.TTN_DAYS], cyrillic: 'Количество дней на возврат ТТН' },
            { latin: ['company_account_id', ContractField.COMPANY_ACCOUNT], cyrillic: 'Расчетный счет (компания)' },
            { latin: ['client_account_id', ContractField.CLIENT_ACCOUNT], cyrillic: 'Расчетный счет (контрагент)' },
            { latin: ['downtime_cost', ContractField.DOWNTIME_COST], cyrillic: 'Стоимость простоя' },
            { latin: ['currency', ContractField.CURRENCY], cyrillic: 'Валюта договора' },
            { latin: ['requirements', ContractField.REQUIREMENTS], cyrillic: 'Требования по договору для импорта в договор-заявку' },
            { latin: ['company_head_position', ContractField.COMPANY_HEAD_POSITION], cyrillic: 'Должность (компания)' },
            { latin: ['company_head_genitive', ContractField.COMPANY_HEAD_GENITIVE], cyrillic: 'В лице (компания)' },
            { latin: ['company_basis', ContractField.COMPANY_BASIS], cyrillic: 'Действует на основании (компания)' },
            { latin: ['number', ContractField.NUMBER], cyrillic: 'Номер договора' },
            { latin: ['client_head_position', ContractField.CLIENT_HEAD_POSITION], cyrillic: 'Должность (контрагент)' },
            { latin: ['client_head_genitive', ContractField.CLIENT_HEAD_GENITIVE], cyrillic: 'В лице (контрагент)' },
            { latin: ['client_basis', ContractField.CLIENT_BASIS], cyrillic: 'Действует на основании (контрагент)' },
            { latin: ['documents', ContractField.DOCUMENTS], cyrillic: 'Документы для оплаты' }
        ]
    },
    contact: {
        table: { latin: 'contact', cyrillic: 'Контакт' },
        fields: [
            idField,
            phoneField,
            emailField,
            { latin: ['main'], cyrillic: 'Основной' },
            { latin: ['fio'], cyrillic: 'ФИО' },
        ]
    },
    client_user_role: {
        table: { latin: 'client_user_role', cyrillic: 'Контрагенты' },
        fields: [
            { latin: ['client_id'], cyrillic: 'Контрагент' },
        ]
    },
    crm_user: {
        table: { latin: 'crm_user', cyrillic: 'Пользователь' },
        fields: [
            idField,
            phoneField,
            emailField,
            commentField,
            { latin: ['last_name', CrmUserField.LAST_NAME], cyrillic: 'Фамилия' },
            { latin: ['active', CrmUserField.ACTIVE], cyrillic: 'Активен' },
            { latin: ['first_name', CrmUserField.FIRST_NAME], cyrillic: 'Имя' },
            { latin: ['inn', CrmUserField.INN], cyrillic: 'ИНН' },
            { latin: ['passport_number', CrmUserField.PASSPORT_NUMBER], cyrillic: 'Номер паспорта' },
            { latin: ['status_id', CrmUserField.STATUS], cyrillic: 'Согласование' },
            { latin: ['licence_date', CrmUserField.LICENCE_DATE], cyrillic: 'Дата выдачи вод. удостоверения' },
            { latin: ['password', CrmUserField.PASSWORD], cyrillic: 'Пароль' },
            { latin: ['snils', CrmUserField.SNILS], cyrillic: 'СНИЛС' },
            { latin: ['mid_name', CrmUserField.MID_NAME], cyrillic: 'Отчество' },
            { latin: ['passport_department', CrmUserField.PASSPORT_DEPARTMENT], cyrillic: 'Кем выдан паспорт' },
            { latin: ['passport_alien', CrmUserField.PASSPORT_ALIEN], cyrillic: 'Не рф' },
            { latin: ['birth_date', CrmUserField.BIRTH_DATE], cyrillic: 'Дата рождения' },
            { latin: ['licence_number', CrmUserField.LICENCE_NUMBER], cyrillic: 'Вод. удостоверение' },
            { latin: ['passport_date', CrmUserField.PASSPORT_DATE], cyrillic: 'Дата выдачи паспорта' }
        ]
    },
    pallet_passport: {
        table: { latin: 'pallet_passport', cyrillic: 'Пасспорт паллеты' },
        fields: [
            idField,
            { latin: ['boxes', PalletPassportField.BOXES], cyrillic: 'Количество мест' },
            { latin: ['box_weight', PalletPassportField.BOX_WEIGHT], cyrillic: 'Вес пустой коробки' },
            { latin: ['gross_weight', PalletPassportField.GROSS_WEIGHT], cyrillic: 'Фактический вес брутто, кг' },
            { latin: ['pallet_weight', PalletPassportField.PALLET_WEIGHT], cyrillic: 'Вес пустого поддона + скрепл. материалов' },
        ]
    },
    production_line: {
        table: { latin: 'production_line', cyrillic: 'Производственная линия' },
        fields: [
            idField,
            { latin: ['line_number', ProductionLineField.LINE_NUMBER], cyrillic: 'Номер линии' },
            { latin: ['storage_id', ProductionLineField.STORAGE], cyrillic: 'Склад производства' }
        ]
    },
    quote: {
        table: { latin: 'quote', cyrillic: 'Заказ' },
        fields: [
            idField,
            descriptionField,
            { latin: ['loading_type_id'], cyrillic: 'Тип погрузки' },
            { latin: ['loading_storage_id', QuoteField.LOADING_STORAGE], cyrillic: 'Склад погрузки' },
            { latin: ['carrier_payment_delay', QuoteField.CARRIER_PAYMENT_DELAY], cyrillic: 'Отсрочка платежа Поставщика' },
            { latin: ['unloading_storage_id', QuoteField.UNLOADING_STORAGE], cyrillic: 'Склад разгрузки' },
            { latin: ['carrier_company_id', QuoteField.CARRIER_COMPANY], cyrillic: 'Организация для Поставщика' },
            { latin: ['client_id', QuoteField.CLIENT], cyrillic: 'Заказчик' },
            { latin: ['carrier_payment_type_id', QuoteField.CARRIER_PAYMENT_TYPE], cyrillic: 'Условия оплаты Поставщика' },
            { latin: ['client_planned_payment_date', QuoteField.CLIENT_PLANNED_PAYMENT_DATE], cyrillic: 'Плановая дата оплаты Заказчика' },
            { latin: ['client_quote_number', QuoteField.CLIENT_QUOTE_NUMBER], cyrillic: 'Номер заказа Заказчика' },
            { latin: ['client_contact_id', QuoteField.CLIENT_CONTACT], cyrillic: 'Контактное лицо Заказчика' },
            { latin: ['carrier_planned_payment_date', QuoteField.CARRIER_PLANNED_PAYMENT_DATE], cyrillic: 'Плановая дата оплаты Поставщика' },
            { latin: ['carrier_company_contact_id', QuoteField.CARRIER_COMPANY_CONTACT], cyrillic: 'Контактное лицо Поставщика' },
            { latin: ['carrier_contract_id', QuoteField.CARRIER_CONTRACT], cyrillic: 'Договор с Поставщиком' },
            { latin: ['carrier_contact_id', QuoteField.CARRIER_CONTACT], cyrillic: 'Контактное лицо Поставщика' },
            { latin: ['carrier_id', QuoteField.CARRIER], cyrillic: 'Поставщик' },
            { latin: ['client_consignee_id', QuoteField.CLIENT_CONSIGNEE], cyrillic: 'Грузополучатель' },
            { latin: ['carrier_payment_method_id', QuoteField.CARRIER_PAYMENT_METHOD], cyrillic: 'Форма оплаты Поставщика' },
            { latin: ['client_payment_method_id', QuoteField.CLIENT_PAYMENT_METHOD], cyrillic: 'Форма оплаты Заказчика' },
            { latin: ['client_contract_id', QuoteField.CLIENT_CONTRACT], cyrillic: 'Договор' },
            { latin: ['unloading_address_id', QuoteField.UNLOADING_ADDRESS], cyrillic: 'Адрес разгрузки' },
            { latin: ['loading_address_id', QuoteField.LOADING_ADDRESS], cyrillic: 'Адрес погрузки' },
            { latin: ['client_company_id', QuoteField.CLIENT_COMPANY], cyrillic: 'Организация для Заказчика' },
            { latin: ['carrier_third_party', QuoteField.CARRIER_THIRD_PARTY], cyrillic: 'Сторонний Поставщик' },
            { latin: ['manager_id', QuoteField.MANAGER], cyrillic: 'Менеджер КО' },
            { latin: ['client_payment_date', QuoteField.CLIENT_PAYMENT_DATETIME], cyrillic: 'Фактическая дата оплаты Заказчика' },
            { latin: ['client_payment_type_id', QuoteField.CLIENT_PAYMENT_TYPE], cyrillic: 'Условия оплаты Заказчика' },
            { latin: ['expire', QuoteField.EXPIRE], cyrillic: 'Действует до' },
            { latin: ['source_id', QuoteField.SOURCE], cyrillic: 'Источник заказа' },
            { latin: ['loading_date', QuoteField.LOADING_DATE], cyrillic: 'Дата погрузки' },
            { latin: ['unloading_date', QuoteField.UNLOADING_DATE], cyrillic: 'Дата разгрузки' },
            { latin: ['status_id', QuoteField.STATUS], cyrillic: 'Статус заказа' },
            { latin: ['storage_status_id', QuoteField.STORAGE_STATUS], cyrillic: 'Статус склада' },
            { latin: ['carrier_payment_date', QuoteField.CARRIER_PAYMENT_DATETIME], cyrillic: 'Фактическая дата оплаты Поставщика' },
            { latin: ['client_payment_delay', QuoteField.CLIENT_PAYMENT_DELAY], cyrillic: 'Отсрочка платежа Заказчика' },
            { latin: ['client_payment_method', QuoteField.CLIENT_PAYMENT_METHOD], cyrillic: 'Форма оплаты Заказчика' },
            { latin: ['created', QuoteField.CREATED], cyrillic: 'Дата заказа' },
        ]
    },
    run: {
        table: { latin: 'run', cyrillic: 'Рейс' },
        fields: [
            idField,
            descriptionField,
            { latin: ['client_price', RunField.CLIENT_PRICE], cyrillic: 'Ставка Заказчика' },
            { latin: ['ttn_number', RunField.TTN_NUMBER], cyrillic: 'Номер ТТН' },
            { latin: ['client_payment_method_id', RunField.CLIENT_PAYMENT_METHOD], cyrillic: 'Форма оплаты Заказчика' },
            { latin: ['carrier_id', RunField.CARRIER], cyrillic: 'Перевозчик' },
            { latin: ['carrier_price', RunField.CARRIER_PRICE], cyrillic: 'Ставка Перевозчика' },
            { latin: ['status_id', RunField.STATUS], cyrillic: 'Статус' },
            { latin: ['client_payment_delay', RunField.CLIENT_PAYMENT_DELAY], cyrillic: 'Отсрочка платежа Заказчика' },
            { latin: ['client_payment_type_id', RunField.CLIENT_PAYMENT_TYPE], cyrillic: 'Условия оплаты Заказчика' },
            { latin: ['carrier_contract_id', RunField.CARRIER_CONTRACT], cyrillic: 'Договор с Перевозчиком' },
            { latin: ['carrier_contact_id', RunField.CARRIER_CONTACT], cyrillic: 'Контактное лицо Перевозчика' },
            { latin: ['created', RunField.CREATED], cyrillic: 'Создан' },
            { latin: ['carrier_payment_date', RunField.CARRIER_PAYMENT_DATE], cyrillic: 'Фактическая дата оплаты Перевозчика' },
            { latin: ['carrier_payment_type_id', RunField.CARRIER_PAYMENT_TYPE], cyrillic: 'Форма оплаты Перевозчика' },
            { latin: ['carrier_company_id', RunField.CARRIER_COMPANY], cyrillic: 'Организация для Перевозчика' },
            { latin: ['client_id', RunField.CLIENT], cyrillic: 'Заказчик' },
            { latin: ['number', RunField.NUMBER], cyrillic: 'Номер' },
            { latin: ['truck_id', RunField.TRUCK], cyrillic: 'Тягач' },
            { latin: ['trailer_id', RunField.TRAILER], cyrillic: 'Полуприцеп' },
            { latin: ['driver_id', RunField.DRIVER], cyrillic: 'Водитель' },
            { latin: ['client_payment_date', RunField.CLIENT_PAYMENT_DATE], cyrillic: 'Фактическая дата оплаты Заказчика' },
            { latin: ['client_contract_id', RunField.CLIENT_CONTRACT], cyrillic: 'Договор с Заказчиком' },
            { latin: ['client_contact_id', RunField.CLIENT_CONTACT], cyrillic: 'Контактное лицо Заказчика' },
            { latin: ['ttn_date', RunField.TTN_DATE], cyrillic: 'Дата ТТН' },
            { latin: ['ttn_status_id', RunField.TTN_STATUS], cyrillic: 'Статус ТТН' },
            { latin: ['storage_status_id', RunField.STORAGE_STATUS], cyrillic: 'Статус склада' },
            { latin: ['manager_id', RunField.LOGISTIC_MANAGER], cyrillic: 'Менеджер ОЛ' },
            { latin: ['client_planned_payment_date', RunField.CLIENT_PLANNED_PAYMENT_DATE], cyrillic: 'Плановая дата оплаты Заказчика' },
            { latin: ['client_company_id', RunField.CLIENT_COMPANY], cyrillic: 'Организация для Заказчика' },
            { latin: ['carrier_planned_payment_date', RunField.CARRIER_PLANNED_PAYMENT_DATE], cyrillic: 'Плановая дата оплаты Перевозчика' },
            { latin: ['carrier_payment_method', RunField.CARRIER_PAYMENT_METHOD], cyrillic: 'Способ оплаты Перевозчика' },
            { latin: ['carrier_payment_delay', RunField.CARRIER_PAYMENT_DELAY], cyrillic: 'Отсрочка платежа Перевозчика' },
            { latin: ['type_id', RunField.TYPE], cyrillic: 'Тип доставки' },
        ]
    },
    run_expense: {
        table: { latin: 'run_expense', cyrillic: 'Доп. расходы' },
        fields: [
            idField,
            commentField,
            { latin: ['item_id', RunExpenseField.ITEM], cyrillic: 'Статья расходов' },
            { latin: ['amount', RunExpenseField.AMOUNT], cyrillic: 'Сумма' },
        ]
    },
    run_point: {
        table: { latin: 'run_point', cyrillic: 'Точка маршрута' },
        fields: [
            idField,
            descriptionField,
            { latin: ['quote_id', RouteField.QUOTE_ID], cyrillic: 'Заказ' },
            { latin: ['contact_id', RouteField.CONTACT], cyrillic: 'Контакт' },
            { latin: ['cargo_price', RouteField.CARGO_PRICE], cyrillic: 'Стоимость груза' },
            { latin: ['tare_quantity', RouteField.TARE_QUANTITY], cyrillic: 'Кол-во тары' },
            { latin: ['weight', RouteField.WEIGHT], cyrillic: 'Вес' },
            { latin: ['storage_id', RouteField.STORAGE], cyrillic: 'Склад' },
            { latin: ['time_from', RouteField.TIME_FROM], cyrillic: 'Время с' },
            { latin: ['duration', RouteField.DURATION], cyrillic: 'Длит-сть ч.' },
            { latin: ['company_id', RouteField.COMPANY], cyrillic: 'Контрагент' },
            { latin: ['is_loading', RouteField.IS_LOADING], cyrillic: 'Загрузка' },
            { latin: ['date', RouteField.DATE], cyrillic: 'Дата' },
            { latin: ['address_id', RouteField.ADDRESS], cyrillic: 'Адрес' },
            { latin: ['client_id', RouteField.CLIENT], cyrillic: 'Контрагент' }
        ]
    },
    shipment_detail: {
        table: { latin: 'shipment_detail', parentDependentCyrillic: {
                'quote': 'Груз',
                'run': 'Тальманская расписка'
            }},
        fields: [
            idField,
            descriptionField,
            { latin: ['talman_datetime', ShipmentDetailField.TALMAN_DATETIME], cyrillic: 'Дата/время инспекции' },
            { latin: ['talman_seal', ShipmentDetailField.TALMAN_SEAL], cyrillic: 'Пломба' },
            { latin: ['status_id', ShipmentDetailField.STATUS], cyrillic: 'Статус' },
            { latin: ['tare_tmc_id', ShipmentDetailField.TARE_TMC], cyrillic: 'Тара' },
            { latin: ['weight_unloaded', ShipmentDetailField.WEIGHT_UNLOADED], cyrillic: 'Вес выгруз' },
            { latin: ['package_per_tare', ShipmentDetailField.PACKAGE_PER_TARE], cyrillic: 'Влож.' },
            { latin: ['client_assortment_id', ShipmentDetailField.CLIENT_ASSORTMENT], cyrillic: 'Наименование заказчика' },
            { latin: ['weight_loaded', ShipmentDetailField.WEIGHT_LOADED], cyrillic: 'Вес загруж' },
            { latin: ['unit_id', ShipmentDetailField.UNIT], cyrillic: 'Ед. изм.' },
            { latin: ['quantity', ShipmentDetailField.QUANTITY], cyrillic: 'Кол-во ед. изм.' },
            { latin: ['weight', ShipmentDetailField.WEIGHT], cyrillic: 'Вес' },
            { latin: ['weight_accepted', ShipmentDetailField.WEIGHT_ACCEPTED], cyrillic: 'Вес принят' },
            { latin: ['sell_price', ShipmentDetailField.SELL_PRICE], cyrillic: 'Цена' },
            { latin: ['net_pack', ShipmentDetailField.NET_PACK], cyrillic: 'Сетка' },
            { latin: ['package_tmc_id', ShipmentDetailField.PACKAGE_TMC], cyrillic: 'Упаковка' },
            { latin: ['temperature_regime', ShipmentDetailField.TEMPERATURE_REGIME], cyrillic: 'Темп. режим' },
            { latin: ['buy_price', ShipmentDetailField.BUY_PRICE], cyrillic: 'Закуп' },
            { latin: ['company_assortment_id', ShipmentDetailField.COMPANY_ASSORTMENT], cyrillic: 'Наименование склада' },
            { latin: ['quantum', ShipmentDetailField.QUANTUM], cyrillic: 'Квант' },
            { latin: ['position', ShipmentDetailField.POSITION], cyrillic: 'Позиция' },
            { latin: ['tare_quantity', ShipmentDetailField.TARE_QUANTITY], cyrillic: 'Кол-во тары' },
        ]
    },
    tmc: {
        table: { latin: 'tmc', cyrillic: 'ТМЦ' },
        fields: [
            idField,
            { latin: ['automatic', TmcField.AUTOMATIC], cyrillic: 'Списывать автоматически' },
            { latin: ['category_id', TmcField.CATEGORY], cyrillic: 'Категория' },
            { latin: ['unit_id', TmcField.UNIT], cyrillic: 'Единица измерения' },
            { latin: ['deduct_rate', TmcField.DEDUCT_RATE], cyrillic: 'Норма списания' },
            { latin: ['linked_tmc_id', TmcField.LINKED_TMC], cyrillic: 'ТМЦ' },
            { latin: ['name', TmcField.NAME], cyrillic: 'Наименование ТМЦ' },
            { latin: ['quantity', TmcField.QUANTITY], cyrillic: 'Кол-во' },
            { latin: ['type_id', TmcField.TYPE], cyrillic: 'Вид номенклатуры' },
        ]
    },
    tmc_operation: {
        table: { latin: 'tmc_operation', cyrillic: 'Операция ТМЦ' },
        fields: [
            idField,
            commentField,
            { latin: ['tmc_id', TmcOperationField.TMC], cyrillic: 'ТМЦ' },
            { latin: ['type_id', TmcOperationField.TYPE], cyrillic: 'Операция' },
            { latin: ['run_id', TmcOperationField.RUN], cyrillic: 'Номер рейса' },
            { latin: ['dt', TmcOperationField.DT], cyrillic: 'Дата' },
            { latin: ['quantity', TmcOperationField.QUANTITY], cyrillic: 'Кол-во' },
            { latin: ['price', TmcOperationField.PRICE], cyrillic: 'Цена' },
            { latin: ['quote_id', TmcOperationField.QUOTE], cyrillic: 'Номер заказа' },
            { latin: ['client_id', TmcOperationField.CLIENT], cyrillic: 'Поставщик' },
            { latin: ['client_address_id', TmcOperationField.CLIENT_ADDRESS], cyrillic: 'Склад' },
            { latin: ['weight', TmcOperationField.WEIGHT], cyrillic: 'Вес' },
            { latin: ['unit_id', TmcOperationField.UNIT], cyrillic: 'Ед. изм.' },
        ]
    },
    vehicle: {
        table: { latin: 'vehicle', cyrillic: 'ТС' },
        fields: [
            idField,
            commentField,
            { latin: ['vin', VehicleField.VIN], cyrillic: 'VIN номер' },
            { latin: ['number', VehicleField.NUMBER], cyrillic: 'Номер' },
            { latin: ['owner', VehicleField.OWNER], cyrillic: 'ФИО собственника' },
            { latin: ['ownership_id', VehicleField.OWNERSHIP], cyrillic: 'Тип собственности' },
            { latin: ['model', VehicleField.MODEL], cyrillic: 'Модель' },
            { latin: ['alien', VehicleField.ALIEN], cyrillic: 'Не РФ' },
            { latin: ['carcass_id', VehicleField.CARCASS], cyrillic: 'Тип кузова' },
            { latin: ['tonnage', VehicleField.TONNAGE], cyrillic: 'Тоннаж' },
            { latin: ['type_id', VehicleField.TYPE], cyrillic: 'Тип' },
            { latin: ['volume', VehicleField.VOLUME], cyrillic: 'Кубатура' },
            { latin: ['pts_info', VehicleField.PTS_INFO], cyrillic: 'Серия номер ПТС' },
            { latin: ['approval_id', VehicleField.APPROVAL], cyrillic: 'Согласование' },
            { latin: ['pts_date', VehicleField.PTS_DATE], cyrillic: 'Дата выдачи ПТС' },
        ]
    },
    vehicle_client: {
        table: { latin: 'vehicle_client', cyrillic: 'Контрагенты ТС' },
        fields: [
            { latin: ['client_id'], cyrillic: 'Контрагент' },
        ]
    },
};

export default fieldToLabelMapping;