import React, { useContext, useMemo } from 'react';
import { Outlet, generatePath } from 'react-router-dom';
import { ContractField } from './clientContractField';
import { ContractListModel } from '../../../../model/contract/contractListModel';
import { Config } from '../../../table/config';
import { ColumnType } from '../../../table/columnType';
import contracts from '../../../../api/contracts';
import { DwTable, onRowDelete } from '../../../table/DwTable';
import { ContractType, ContractTypeNames } from '../../../../model/enums/ContractType';
import { ClientFormContext } from '../ClientForm';
import { PATH_CP_CLIENT_CONTRACT } from '../../../../routerPaths';
import util from '../../../../common/util';
import { RemoveButton } from '../../../table/RemoveButton';

const formatContractName = (model: ContractListModel) => {
    return ContractTypeNames.get(model?.type) === ContractType.CLIENT
        ? 'Договор с Заказчиком'
        : 'Договор с Перевозчиком';
};

const useTableConfig: (
    clientId: number
) => Config<ContractListModel> = (clientId) =>
    useMemo(() => {
        return Config.builder<ContractListModel>()
            .column((builder) => builder.id().sortable(false).filterable(false))
            .column((builder) =>
                builder.text('Номер', ContractField.NUMBER).sortable(false).filterable(false)
            )
            .column((builder) =>
                builder
                    .text('Наименование', ContractField.TYPE)
                    .getter((model) => formatContractName(model))
                    .sortable(false)
                    .filterable(false)
                    .cls('type')
            )
            .column((builder) =>
                builder
                    .label('Вид договора')
                    .field(ContractField.TYPE)
                    .type(ColumnType.CONTRACT_TYPE)
                    .sortable(false)
                    .filterable(false)
                    .cls('type')
            )
            .column((builder) =>
                builder.date('Дата договора', ContractField.SIGNED).filterable(false)
            )
            .column((builder) =>
                builder.date('Действует с', ContractField.BEGIN_DATE).filterable(false)
            )
            .column((builder) =>
                builder.date('Действует до', ContractField.END_DATE).filterable(false)
            )
            .column((builder) =>
                builder
                    .label('Статус')
                    .field(ContractField.STATUS)
                    .type(ColumnType.CONTRACT_STATUS)
                    .sortable(false)
                    .filterable(false)
                    .cls('status')
            )
            .column((builder) =>
                builder
                    .boolean('Основной', ContractField.MAIN)
                    .sortable(false)
                    .filterable(false)
                    .cls('boolean')
            )
            .column((builder) =>
                builder
                    .cls('text-center')
                    .sortable(false)
                    .filterable(false)
                    .getter((model, _, refresh) => (
                        <RemoveButton
                            onClick={() =>
                                onRowDelete(refresh, () => contracts.delete(model.id))
                            }
                        />
                    ))
                    .width('45px')
            )
            .columnsSelector(false)
            .formUrl(
                generatePath(PATH_CP_CLIENT_CONTRACT, {
                    clientId: util.stringOrEmpty(clientId),
                    contractId: '',
                })
            )
            .load((ctx) => contracts.listForClient(ctx.state.request, clientId))
            .id(`client_${clientId}_contracts`)
            .build();
    }, [clientId]);

const ClientContracts: React.FC = () => {
    const {
        client: { id: clientId },
    } = useContext(ClientFormContext);
    const config = useTableConfig(clientId);

    return (
        <>
            <div className='contracts d-flex mb-3'>
                <DwTable header='Договоры' config={config} />
            </div>
            <Outlet />
        </>
    );
};

export default ClientContracts;
