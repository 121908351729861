export enum ShipmentDetailField {
    QUOTE_ID = 'quoteId',
    STATUS = 'status',
    POSITION = 'position',
    COMPANY_ASSORTMENT = 'companyAssortment',
    CLIENT_ASSORTMENT = 'clientAssortment',
    NET_PACK = 'netPack',
    QUANTUM = 'quantum',
    PACKAGE_PER_TARE = 'packagePerTare',
    BUY_PRICE = 'buyPrice',
    SELL_PRICE = 'sellPrice',
    WEIGHT = 'weight',
    TARE_QUANTITY = 'tareQuantity',
    TOTAL_COST = 'totalCost',
    WEIGHT_UNLOADED = 'weightUnloaded',
    WEIGHT_ACCEPTED = 'weightAccepted',
    WEIGHT_LOADED = 'weightLoaded',
    TEMPERATURE_REGIME = 'temperatureRegime',
    DESCRIPTION = 'description',
    PACKAGE_TMC = 'packageTmc',
    TARE_TMC = 'tareTmc',
    UNIT = 'unit',
    QUANTITY = 'quantity',
    ASSORTMENT_OR_TMC = 'assortmentOrTmc',
    TMC = 'tmc',
    TALMAN_SEAL = 'talmanSeal',
    TALMAN_DATETIME = 'talmanDatetime',
}