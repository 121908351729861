import React from 'react';
import { TYPE_OPTIONS_FOR_ASSORTMENT } from '../../../model/assortment';
import { TmcCategory } from '../../../model/tmc/tmcModel';
import { DictionaryType } from '../../control/option';
import CheckboxInput from '../../form/CheckboxInput';
import NumberInput from '../../form/NumberInput';
import Options from '../../form/Options';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';
import StringInput from '../../form/StringInput';
import { Tmc as TmcSelector } from '../../form/Tmc';
import { TmcField } from './tmcField';

const Tmc: React.FC = () => {
    return (
        <div className='card h-100'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-lg-3'>
                        Наименование
                        <StringInput id={TmcField.NAME} />
                    </div>

                    <div className='col-lg-2'>
                        Единица измерения
                        <Options id={TmcField.UNIT} dictionaryType={DictionaryType.UNIT} />
                    </div>

                    <div className='col-lg-2'>
                        Норма списания
                        <NumberInput id={TmcField.DEDUCT_RATE} />
                    </div>

                    <div className='col-lg-3 d-flex align-items-center'>
                        <div className='custom-switch'>
                            <CheckboxInput
                                id={TmcField.AUTOMATIC}
                                label='Списывать автоматически'
                            />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-3'>
                        Категория
                        <Options
                            id={TmcField.CATEGORY}
                            dictionaryType={DictionaryType.TMC_CATEGORY}
                        />
                    </div>

                    <div className='col-lg-3'>
                        ТМЦ
                        <TmcSelector
                            id={TmcField.LINKED_TMC}
                            tmcCategories={[
                                TmcCategory.PACKAGE,
                                TmcCategory.TARE,
                                TmcCategory.PACKAGE_OR_TARE,
                            ]}
                        />
                    </div>

                    <div className='col-lg-3'>
                        Вид номенклатуры
                        <Options
                            id={TmcField.TYPE}
                            dictionaryType={DictionaryType.ASSORTMENT_TYPE}
                            filterCallback={(option) => {
                                return !(TYPE_OPTIONS_FOR_ASSORTMENT as readonly string[]).includes(
                                    option?.label
                                );
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </div>
    );
};

export default Tmc;
