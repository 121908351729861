import React from 'react';
import { generatePath, Outlet } from 'react-router-dom';
import productionLines from '../../../api/productionLine';
import util from '../../../common/util';
import { lineNumberValidator } from '../../../common/validation/productionLineValidators';
import { ProductionLineModel } from '../../../model/productionLine/productionLineModel';
import {
    PATH_CP_PRODUCTION_LINE,
    PATH_CP_PRODUCTION_LINES,
    PATH_VARIABLE_PRODUCTION_LINE_ID,
    PATH_CP_PRODUCTION_LINE_HISTORY,
} from '../../../routerPaths';
import ContextError from '../../form/ContextError';
import { DwForm, useFieldValue } from '../../form/DwForm';
import DwFormTabs from '../../form/DwFormTabs';
import { FormConfig } from '../../form/formConfig';
import { ProductionLineField } from './productionLineField';

const formConfig = FormConfig.builder<ProductionLineModel>()
    .number(
        ProductionLineField.ID,
        (m) => m.id,
        (m, v) => (m.id = v)
    )
    .number(
        ProductionLineField.LINE_NUMBER,
        (m) => m.lineNumber,
        (m, v) => (m.lineNumber = v),
        (m) => lineNumberValidator(m)
    )
    .requiredOption(
        ProductionLineField.STORAGE,
        (m) => m.storage,
        (m, v) => (m.storage = v)
    )
    .load((id) => productionLines.get(id))
    .submit(productionLines.save)
    .redirectUrl(PATH_CP_PRODUCTION_LINES)
    .idPathVariableName(PATH_VARIABLE_PRODUCTION_LINE_ID)
    .build();

const ProductionLineForm: React.FC = () => {
    return (
        <DwForm config={formConfig}>
            <ProductionLineLayout />
        </DwForm>
    );
};

/**
 *   Just a wrapper to access form context for further usage (e.g. adding field value to Tab name)
 */
const ProductionLineLayout: React.FC = () => {
    const productionLineId = useFieldValue(ProductionLineField.ID);
    const currentLineNumber = useFieldValue(ProductionLineField.LINE_NUMBER);
    const isNew: boolean = !productionLineId;

    return (
        <>
            <DwFormTabs
                items={[
                    {
                        name: isNew ? 'Новая линия' : `Линия #${currentLineNumber}`,
                        path: generatePath(PATH_CP_PRODUCTION_LINE, {
                            [PATH_VARIABLE_PRODUCTION_LINE_ID]: util.stringOrEmpty(productionLineId),
                        }),
                        end: true,
                    },
                    {
                        name: 'История',
                        path: generatePath(PATH_CP_PRODUCTION_LINE_HISTORY, {
                            [PATH_VARIABLE_PRODUCTION_LINE_ID]: util.stringOrEmpty(productionLineId),
                        }),
                        end: true,
                        disabled: isNew,
                    },
                ]}
            />
            <ContextError />
            <Outlet />
        </>
    );
};

export default ProductionLineForm;
