import { post } from './rest';
import { PagedResponse } from './pagedResponse';
import { AuditListModel } from '../model/audit/auditListModel';
import { AuditListRequest } from '../component/table/listRequest';

const audit = {
    list: (request: AuditListRequest) => post<PagedResponse<AuditListModel>>('audit/list', request),
};

export default audit;
