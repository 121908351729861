import React, {ReactElement, useCallback, useContext, useEffect, useMemo} from 'react';
import {FormConfig} from './formConfig';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {ApiResponse} from '../../api/apiResponse';
import './form.scss';
import clsx from 'clsx';
import {reducer} from './reducer';
import {ActionType} from './action';
import {Context, DwSubmitEvent, DwSubmitOptions} from './context';
import {FieldConfig} from './fieldConfig';
import { SEARCH_PARAM_FORM_VERSION } from '../../routerPaths';
import util from '../../common/util';
import { generatePathFromNewEntity, useMatchingRoute } from '../../common/route';
import {showSuccessToast} from '../control/showToast';

type Props<T> = {
    id?: number;
    config: FormConfig<T>;
    children: React.ReactElement | React.ReactElement[];
    className?: string;
};

const FormContext = React.createContext<Context<any>>({} as Context<any>);

const DwForm: <T>(p: Props<T>) => ReactElement<Props<T>> = ({children, config, className}) => {
    const navigate = useNavigate();
    const entityId = useParams()[config.getIdPathVariableName()];
    const {formVersion, incrementFormVersion} = useFormVersion();
    const routeMatch = useMatchingRoute(config.getIdPathVariableName());

    const initState = useMemo(() => {
        return {
            config,
            model: config.getEmptyModel(),
            changes: config.getEmptyModel(),
            emptyModel: config.getEmptyModel(),
            error: '',
            errors: new Map<string, string>(),
        };
    }, [config]);
    const [state, dispatch] = React.useReducer(reducer, initState);
    const replaceModel = useCallback((model: any) => dispatch({ type: ActionType.MODEL, model }), [dispatch]);

    const setError = useCallback((error: string) => dispatch({ type: ActionType.ERROR, value: error }), [dispatch]);
    const setErrors = useCallback((errors: Map<string, string>) => dispatch({ type: ActionType.ERRORS, errors: errors }), [dispatch]);
    const resetErrors = useCallback(() => {
        setErrors(new Map<string, string>());
        setError('');
    }, [setError, setErrors]);

    const validate = useCallback(async () => {
        const results: [string, string][] = await Promise.all(
            config.getValidators().map(async (it): Promise<[string, string]> => {
                return [it.id, await it.validator(state.model as any)];
            })
        );
        const map = new Map<string, string>(results.filter((pair) => !!pair[1]));
        setErrors(map);
        return map;
    }, [config, setErrors, state.model]);

    const submit = useCallback(async (): Promise<any> => {
        const stateModel = state.model as any;
        const changes = state.changes as any;
        const model = stateModel.id ? {...changes, id: stateModel.id} : stateModel;
        const areThereAnyChanges = !stateModel.id || Object.keys(changes).length;
        let error = '';
        const result: any = areThereAnyChanges ? await config.getSubmit()(model) : '';
        if (typeof result === 'string') {
            error = result;
        } else if (typeof result === 'object' && 'error' in result) {
            error = (result as ApiResponse).error;
        }
        setError(error);
        return result;
    }, [config, setError, state.changes, state.model]);

    const onSubmit = useCallback(async (e?: DwSubmitEvent, options?: DwSubmitOptions) => {
        const { showSaveToast = false, withRedirect = true } = options || {};

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        resetErrors();
        const validationResult = await validate();
        let noErrors = validationResult.size === 0;
        let submitResult = null;
        if (noErrors) {
            submitResult = await submit();
            noErrors = !submitResult?.error;
        }
        if (noErrors) {
            config.getAfterSubmit()(submitResult, withRedirect, incrementFormVersion);
            if (withRedirect) {
                navigate(config.getRedirectUrl(state.model as any)); // save and exit
            } else {
                if (entityId) {
                    incrementFormVersion(); // save and reload without exit
                } else {
                    navigate(generatePathFromNewEntity({ // save new and redirect by saved ID
                        routeMatch: routeMatch,
                        entityId: submitResult,
                        defaultUrl: config.getRedirectUrl(state.model as any)
                    }), {
                        replace: true
                    });
                }
                if (showSaveToast) {
                    showSuccessToast('Форма сохранена');
                }
            }
        }
    }, [config, entityId, navigate, resetErrors, incrementFormVersion, submit, validate, routeMatch]);

    const onCancel = useCallback(() => {
        return navigate(config.getRedirectUrl(state.model as any));
    }, [navigate, config, state.model]);

    const context = useMemo(() => ({
        state,
        replaceModel,
        setFieldValue: (config, value) => dispatch({ type: ActionType.SET, config, value }),
        setFieldValues: (setter) => dispatch({ type: ActionType.SET, setter }),
        setFieldError: (id, error) => dispatch({ type: ActionType.FIELD_ERROR, id: id, value: error }),
        setErrors,
        setError,
        resetErrors,
        dwSubmit: onSubmit,
        dwSubmitWithoutRedirect: (e?: DwSubmitEvent, options?: DwSubmitOptions) => {
            const { showSaveToast = true, ...rest } = options || {};
            return onSubmit(e, { ...rest, showSaveToast, withRedirect: false });
        },
        dwCancel: onCancel,
    } as Context<any>), [onSubmit, replaceModel, setError, setErrors, state]);

    useEffect(() => {
        let shouldUpdate = true;
        const id = entityId ? Number(entityId) : -1;
        const load = config.getLoad() && (id > 0 || !config.getIdPathVariableName());
        const modelPromise = load ? config.getLoad()(id) : config.getInitialModelGetter()(config);
        modelPromise.then((m) => shouldUpdate && replaceModel(m));
        return () => { shouldUpdate = false; };
        // formVersion should be in dependencies to reload form on save without redirect
    }, [config, entityId, formVersion, replaceModel]);

    return (
        <form className={clsx('dw-form', className)}>
            <FormContext.Provider value={context}>{children}</FormContext.Provider>
        </form>
    );
};

const useDwSubmit = () => {
    const {dwSubmit} = useContext(FormContext);
    return dwSubmit;
}

const useDwCancel = () => {
    const {dwCancel} = useContext(FormContext);
    return dwCancel;
}

const useFormVersion = () => {
    const [searchParams, setSearchParams] = useSearchParams(),
        formVersion = util.toNumber(searchParams.get(SEARCH_PARAM_FORM_VERSION) ?? '') ?? 0;
    return {
        formVersion,
        incrementFormVersion: () => setSearchParams({[SEARCH_PARAM_FORM_VERSION]: util.toString(formVersion + 1) ?? ''})
    };
}

/**
 *  Use this hook to access field value of current form context by field name
 */
const useFieldValue = (fieldName: string) => {
    const context = useContext(FormContext);
    return context.state.config.field(fieldName)?.getter(context.state.model);
};

function useSetFieldValue<T>() {
    const {state: {config}, setFieldValue} = useContext(FormContext);
    return (fieldName: string, value: T) => {
        const fieldConfig = config.field(fieldName) ?? {};
        setFieldValue(fieldConfig, value);
    };
};

const useFormRedirectUrl = () => {
    const context = useContext(FormContext);
    const { state } = context;
    return state.config?.getRedirectUrl(state.model as any);
};

const validateField = async (
    context: Context<any>,
    fieldConfig: FieldConfig<any, any>,
    model: any
) => {
    if (fieldConfig.validator) {
        const err = await fieldConfig.validator(model);
        context.setFieldError(fieldConfig.id, err);
    }
};

const useIsLoaded = () => {
    const context = useContext(FormContext);
    const {config, model} = context.state;
    const entityId = useParams()[config.getIdPathVariableName()];
    const isNew = !entityId, isLoaded = Number(entityId) === model.id;
    return isNew || isLoaded;
};

export {DwForm, useDwSubmit, useDwCancel, useFieldValue, useSetFieldValue, useFormRedirectUrl, validateField, useIsLoaded, FormContext, useFormVersion};
