export enum StorageStateChartField {
    ASSORTMENT_NAME = 'assortmentName',
    STORAGE_TOTAL = 'storageTotal',
    WEIGHT_ON_WAY = 'weightOnWay',
    WEIGHT_STORAGE_RESERVED = 'weightStorageReserved',
    WEIGHT_STORAGE_AVAILABLE = 'weightStorageAvailable',
    WEIGHT_PRODUCTION = 'weightProduction',
    WEIGHT_TO_LOAD = 'weightToLoad',
    WEIGHT_LOADED = 'weightLoaded',
}
