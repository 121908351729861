import React from 'react';
import { Outlet } from 'react-router';
import { generatePath } from 'react-router-dom';
import tmc from '../../../api/tmc';
import util from '../../../common/util';
import {
    PATH_CP_TMC,
    PATH_CP_TMCS,
    PATH_CP_TMC_HISTORY,
    PATH_VARIABLE_TMC_ID,
} from '../../../routerPaths';
import ContextError from '../../form/ContextError';
import { DwForm, useFieldValue } from '../../form/DwForm';
import DwFormTabs from '../../form/DwFormTabs';
import { FormConfig } from '../../form/formConfig';
import { TmcField } from './tmcField';
import type { TmcModel } from '../../../model/tmc/tmcModel';
import { automaticRequiredValidator } from '../../../common/validation/tmcValidators';

const formConfig = FormConfig.builder<TmcModel>()
    .number(
        TmcField.ID,
        (m) => m.id,
        (m, v) => (m.id = v)
    )
    .requiredText(
        TmcField.NAME,
        (m) => m.name,
        (m, v) => (m.name = v)
    )
    .requiredOption(
        TmcField.UNIT,
        (m) => m.unit,
        (m, v) => (m.unit = v)
    )
    .boolean(
        TmcField.AUTOMATIC,
        (m) => m.automatic,
        (m, v) => (m.automatic = v)
    )
    .number(
        TmcField.DEDUCT_RATE,
        (m) => m.deductRate,
        (m, v) => (m.deductRate = v),
        (m) => automaticRequiredValidator(m.automatic, m.deductRate)
    )
    .requiredOption(
        TmcField.CATEGORY,
        (m) => m.category,
        (m, v) => (m.category = v)
    )
    .option(
        TmcField.LINKED_TMC,
        (m) => m.linkedTmc,
        (m, v) => (m.linkedTmc = v)
    )
    .requiredOption(
        TmcField.TYPE,
        (m) => m.type,
        (m, v) => (m.type = v)
    )
    .load((id) => tmc.get(id))
    .submit(tmc.save)
    .redirectUrl(PATH_CP_TMCS)
    .idPathVariableName(PATH_VARIABLE_TMC_ID)
    .build();

const TmcForm: React.FC = () => {
    return (
        <DwForm config={formConfig}>
            <TmcLayout />
        </DwForm>
    );
};

/**
 *   Just a wrapper to access form context for further usage (e.g. adding field value to Tab name)
 */
const TmcLayout: React.FC = () => {
    const tmcId = useFieldValue(TmcField.ID);
    const tmcName = useFieldValue(TmcField.NAME);
    const isNew: boolean = !tmcId;

    return (
        <>
            <DwFormTabs
                items={[
                    {
                        name: isNew ? 'Новая ТМЦ' : tmcName,
                        path: generatePath(PATH_CP_TMC, {
                            [PATH_VARIABLE_TMC_ID]: util.stringOrEmpty(tmcId),
                        }),
                        end: true,
                    },
                    {
                        name: 'История',
                        path: generatePath(PATH_CP_TMC_HISTORY, {
                            [PATH_VARIABLE_TMC_ID]: util.stringOrEmpty(tmcId),
                        }),
                        end: true,
                        disabled: isNew,
                    },
                ]}
            />
            <ContextError />
            <Outlet />
        </>
    );
};

export default TmcForm;
