import React from 'react';
import { Config } from '../../table/config';
import { DwTable, onRowDelete } from '../../table/DwTable';
import { RemoveButton } from '../../table/RemoveButton';
import { PATH_CP_ASSORTMENT, PATH_VARIABLE_ASSORTMENT_ID } from '../../../routerPaths';
import { generatePath } from 'react-router-dom';
import { AssortmentField } from './assortmentField';
import { TmcField } from '../tmc/tmcField';
import { AssortmentListModel } from '../../../model/assortment';
import assortments from '../../../api/assortments';
import { ColumnType } from '../../table/columnType';

const config = Config.builder<AssortmentListModel>()
                .column((builder) => builder.id().filterable(false))
                .column((builder) => builder.text('Наименование', AssortmentField.VALUE))
                .column((builder) => builder.text('Сорт', AssortmentField.GRADE).filterable(false))
                .column((builder) =>
                    builder.text('Единица измерения', AssortmentField.UNIT).filterable(false)
                )
                .column((builder) =>
                    builder.integer('Количество', AssortmentField.QUANTITY).filterable(false)
                )
                .column((builder) =>
                    builder.boolean('Активен', AssortmentField.ACTIVE).filterable(false)
                )
                .column((builder) =>
                    builder
                        .label('Вид номенклатуры')
                        .field(TmcField.TYPE)
                        .type(ColumnType.ASSORTMENT_TYPE)
                        .sortable(false)
                )
                .column((builder) =>
                    builder
                        .cls('text-center')
                        .sortable(false)
                        .filterable(false)
                        .getter((model, _, refresh) => (
                            <RemoveButton
                                onClick={() =>
                                    onRowDelete(refresh, () => assortments.delete(model.id))
                                }
                            />
                        ))
                        .width('45px')
                )
                .outerFilter((builder) => builder.field('storage').type(ColumnType.STORAGE))
                .load((ctx) => assortments.list(ctx.state.request))
                .formUrl(generatePath(PATH_CP_ASSORTMENT, { [PATH_VARIABLE_ASSORTMENT_ID]: '' }))
                .id('storages')
                .build();

export const AssortmentList: React.FC = () => {
    return (
        <div className='d-flex'>
            <DwTable header='Номенклатура' config={config} />
        </div>
    );
};
