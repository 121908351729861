export const PROMISE_OK = '';
export const DEV_UI_HOST = 'localhost:3000';
export const DEV_SERVER_PORT = '8080';
export const DEV_API_URL = `http://localhost:${DEV_SERVER_PORT}`;
export const STAGE_UI_HOST = 'erp-dev.agrorom.ru';
export const DOMESTIC = 'domestic';
export const MOBILE_DEVICE_MAX_WIDTH = 768;

export enum RestUrlPrefix {
    API = '/api',
    WS = '/ws',
}

export enum WebSocketSubscriptionUrl {
    EDI_INTEGRATION = '/integrations/edi',
    STORAGE_ASSEMBLY = '/storages/assembly',
    STORAGE_DISTRIBUTION = '/storages/distribution',
}
