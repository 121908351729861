export enum VehicleField {
    ID = 'id',
    CLIENTS = 'clients',
    TYPE = 'type',
    OWNERSHIP = 'ownership',
    CARCASS = 'carcass',
    APPROVAL = 'approval',
    VIN = 'vin',
    MODEL = 'model',
    NUMBER = 'number',
    ALIEN = 'alien',
    VOLUME = 'volume',
    TONNAGE = 'tonnage',
    PTS_INFO = 'ptsInfo',
    PTS_DATE = 'ptsDate',
    OWNER = 'owner',
    COMMENT = 'comment',
    ATTACHMENTS = 'attachmentListModel',
}