import React from 'react';
import { ColumnType } from '../table/columnType';
import { Config } from '../table/config';
import { DwTable } from '../table/DwTable';
import { EdiMessageListModel } from '../../model/edi/ediMessageListModel';
import edi from '../../api/edi';
import { Outlet, generatePath } from 'react-router-dom';
import { PATH_EDI_MESSAGE_TO_QUOTE, PATH_QUOTE, PATH_RUN, PATH_VARIABLE_EDI_MESSAGE_ID, PATH_VARIABLE_QUOTE_ID, PATH_VARIABLE_RUN_ID } from '../../routerPaths';
import util from '../../common/util';
import { EdiMessageField } from '../../model/edi/ediMessageModel';
import SourcesDropdown from '../quotes/sources/SourcesDropdown';
import "./edi-message-table.scss";
import { TableRefreshWrapper } from '../table/TableRefreshWrapper';

const tableConfig = Config.builder<EdiMessageListModel>()
    .column((builder) => builder.id())
    .column((builder) => builder.text('Номер', EdiMessageField.NUMBER))
    .column((builder) => builder.date('Дата', EdiMessageField.DT))
    .column((builder) => builder.money('Сумма без НДС', EdiMessageField.NET_AMOUNT))
    .column((builder) => builder.money('Сумма с НДС', EdiMessageField.AMOUNT))
    .column((builder) => builder.label('Контрагент').field(EdiMessageField.SELLER).type(ColumnType.COMPANY))
    .column((builder) => builder.label('Торговая сеть').field(EdiMessageField.PARTY).type(ColumnType.EDI_PARTY))
    .column((builder) => builder.label('Заказчик').field(EdiMessageField.BUYER).type(ColumnType.CLIENT))
    .column((builder) => builder.dateTime('Дата ответа', EdiMessageField.RESPONSE_DT))
    .column((builder) => builder.dateTime('Дата поставки', EdiMessageField.REQUESTED_DELIVERY_DT))
    .column((builder) => builder.text('Адрес', EdiMessageField.ADDRESS))
    .column((builder) => builder.label('Грузополучатель').field(EdiMessageField.CONSIGNEE).type(ColumnType.CLIENT))
    .column((builder) =>
        builder
            .integer('ID заказа', EdiMessageField.QUOTE_ID)
            .getter((m) => m.quoteId && <a target='_blank' rel="noreferrer" href={generatePath(PATH_QUOTE, { [PATH_VARIABLE_QUOTE_ID]: m.quoteId.toString() })}>{m.quoteId}</a>)
            .withEmptyValuesFilter(true)
            .sortable(false)
    )
    .column((builder) =>
        builder.text('Номер заказа', EdiMessageField.QUOTE_NUMBER)
            .withEmptyValuesFilter(true)
            .sortable(false)
    )
    .column((builder) =>
        builder.text('Номер рейса', EdiMessageField.RUN_NUMBER)
            .getter((m) => m.runId && <a target='_blank' rel="noreferrer" href={generatePath(PATH_RUN, { [PATH_VARIABLE_RUN_ID]: m.runId.toString() })}>{m.runNumber}</a>)
            .filterable(false)
            .sortable(false)
    )
    .rowClassNameFn((m) => m.hasNewVersion ? 'has-changes' : '')
    .defaultHidden([EdiMessageField.SELLER])
    .load((ctx) => edi.list(ctx.state.request))
    .onClick((id, navigate, model) => {
        navigate(
            generatePath(PATH_EDI_MESSAGE_TO_QUOTE, {
                [PATH_VARIABLE_EDI_MESSAGE_ID]: util.stringOrEmpty(id)
            })
        );
    })
    .id('edi_message')
    .withAdd(false)
    .captionControl(() => <SourcesDropdown />)
    .build();

const EdiMessageList: React.FC = () => {
    return (
        <TableRefreshWrapper>
            <div className='d-flex'>
                <DwTable header='Заказы EDI' config={tableConfig} />
            </div>
            <div>
                <Outlet />
            </div>
        </TableRefreshWrapper>
    );
};

export default EdiMessageList;
