export enum AssortmentField {
    ID = 'id',
    VALUE = 'value',
    GRADE = 'grade',
    UNIT = 'unit',
    QUANTITY = 'quantity',
    ACTIVE = 'active',
    NDS_MODE = 'ndsMode',
    TYPE = 'type',
}
