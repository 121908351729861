import React from 'react';
import { Outlet } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import ClientConsignee from './component/control-panel/client/consignees/ClientConsignee';
import ClientConsignees from './component/control-panel/client/consignees/ClientConsignees';
import StorageStateChart from './component/storage/charts/StorageStateChart';
import StorageLoading from './component/storage/loading/StorageLoading';
import {
    PATH_BASE,
    PATH_CP,
    PATH_CP_ASSORTMENT,
    PATH_CP_ASSORTMENT_HISTORY,
    PATH_CP_ASSORTMENTS,
    PATH_CP_CLIENT,
    PATH_CP_CLIENT_ACCOUNT,
    PATH_CP_CLIENT_ACCOUNTS,
    PATH_CP_CLIENT_ADDRESS,
    PATH_CP_CLIENT_ADDRESSES,
    PATH_CP_CLIENT_ASSORTMENT,
    PATH_CP_CLIENT_ASSORTMENT_FORM,
    PATH_CP_CLIENT_CONSIGNEE,
    PATH_CP_CLIENT_CONSIGNEES,
    PATH_CP_CLIENT_CONTACT,
    PATH_CP_CLIENT_CONTACTS,
    PATH_CP_CLIENT_CONTRACT,
    PATH_CP_CLIENT_CONTRACTS,
    PATH_CP_CLIENT_HISTORY,
    PATH_CP_CLIENTS,
    PATH_CP_COMPANIES,
    PATH_CP_COMPANY,
    PATH_CP_COMPANY_ACCOUNT,
    PATH_CP_COMPANY_ACCOUNTS,
    PATH_CP_COMPANY_ADDRESS,
    PATH_CP_COMPANY_ADDRESSES,
    PATH_CP_COMPANY_FACSIMILE,
    PATH_CP_COMPANY_HISTORY,
    PATH_CP_DICTIONARY_EDIT,
    PATH_CP_DICTIONARY_LIST,
    PATH_CP_DRIVER,
    PATH_CP_DRIVER_HISTORY,
    PATH_CP_DRIVERS,
    PATH_CP_INTEGRATIONS,
    PATH_CP_INTEGRATIONS_DADATA,
    PATH_CP_INTEGRATIONS_EDI,
    PATH_CP_PRODUCTION_LINE,
    PATH_CP_PRODUCTION_LINE_HISTORY,
    PATH_CP_PRODUCTION_LINES,
    PATH_CP_STORAGE,
    PATH_CP_STORAGE_CONTACT,
    PATH_CP_STORAGE_CONTACTS,
    PATH_CP_STORAGES,
    PATH_CP_TMC,
    PATH_CP_TMC_HISTORY,
    PATH_CP_TMCS,
    PATH_CP_USER,
    PATH_CP_USER_HISTORY,
    PATH_CP_USERS,
    PATH_CP_VEHICLE,
    PATH_CP_VEHICLE_HISTORY,
    PATH_CP_VEHICLES,
    PATH_EDI_MESSAGE_TO_QUOTE,
    PATH_EDI_MESSAGES,
    PATH_KPP,
    PATH_LOGIN,
    PATH_QUOTE,
    PATH_QUOTE_HISTORY,
    PATH_QUOTES,
    PATH_RUN,
    PATH_RUN_ATTACHMENTS,
    PATH_RUN_HISTORY,
    PATH_RUN_MAP,
    PATH_RUNS,
    PATH_STORAGE,
    PATH_STORAGE_ASSEMBLY,
    PATH_STORAGE_ASSEMBLY_ASSORTMENT,
    PATH_STORAGE_ASSEMBLY_ASSORTMENT_CLIENT_VIEW,
    PATH_STORAGE_ASSEMBLY_PLAN,
    PATH_STORAGE_ASSORTMENT_OPERATION,
    PATH_STORAGE_ASSORTMENT_OPERATION_HISTORY,
    PATH_STORAGE_ASSORTMENT_OPERATIONS,
    PATH_STORAGE_DISTRIBUTION,
    PATH_STORAGE_QUOTES,
    PATH_STORAGE_TMC_OPERATION,
    PATH_STORAGE_TMC_OPERATION_HISTORY,
    PATH_STORAGE_TMC_OPERATIONS,
    PATH_STORAGE_DISTRIBUTION_ASSORTMENT,
    PATH_STORAGE_DISTRIBUTION_ASSORTMENT_CLIENT,
    PATH_RUN_TALMANS,
    PATH_RUN_TALMAN,
    PATH_STORAGE_LOADING,
    PATH_CP_INTEGRATIONS_EDI_SETTINGS,
    PATH_CP_INTEGRATIONS_EDI_PARTY,
    PATH_VARIABLE_QUOTE_ID,
    PATH_VARIABLE_RUN_ID,
    PATH_VARIABLE_ASSORTMENT_OPERATION_ID,
    PATH_VARIABLE_TMC_OPERATION_ID,
    PATH_VARIABLE_CLIENT_ID,
    PATH_VARIABLE_COMPANY_ID,
    PATH_VARIABLE_USER_ID,
    PATH_VARIABLE_VEHICLE_ID,
    PATH_VARIABLE_TMC_ID,
    PATH_VARIABLE_ASSORTMENT_ID,
    PATH_VARIABLE_PRODUCTION_LINE_ID,
    PATH_VARIABLE_STORAGE_ID,
    PATH_CP_STORAGE_HISTORY, PATH_VARIABLE_DRIVER_ID,
    PATH_CP_STORAGE_CHARTS,
    PATH_CP_STORAGE_STATE_CHART,
} from './routerPaths';
import Login from './component/layout/login/Login';
import Quotes from './component/quotes/Quotes';
import Quote from './component/quotes/Quote';
import QuoteForm from './component/quotes/QuoteForm';
import Clients from './component/control-panel/client/Clients';
import Client from './component/control-panel/client/Client';
import ClientForm from './component/control-panel/client/ClientForm';
import { DictionaryList } from './component/control-panel/dictionary/DictionaryList';
import { DictionaryForm } from './component/control-panel/dictionary/DictionaryForm';

import ClientContacts from './component/control-panel/client/contacts/ClientContacts';
import ClientContact from './component/control-panel/client/contacts/ClientContact';

import ClientContracts from './component/control-panel/client/contracts/ClientContracts';
import ClientContract from './component/control-panel/client/contracts/ClientContract';

import ClientAddresses from './component/control-panel/client/addresses/ClientAddresses';
import ClientAddress from './component/control-panel/client/addresses/ClientAddress';

import RunForm from './component/runs/RunForm';
import Run from './component/runs/Run';
import Runs from './component/runs/Runs';
import RunMap from './component/runs/map/RunMap';
import RunAttachmentsArea from './component/runs/attachments/RunAttachmentsArea';

import Dadata from './component/control-panel/settings/Dadata';
import EdiSettings from './component/control-panel/settings/EdiSettings';

import { ClientAssortment } from './component/control-panel/client/assortment/ClientAssortment';
import { ClientAssortmentForm } from './component/control-panel/client/assortment/ClientAssortmentForm';

import Companies from './component/control-panel/company/Companies';
import Company from './component/control-panel/company/Company';
import CompanyForm from './component/control-panel/company/CompanyForm';
import CompanyAddresses from './component/control-panel/company/addresses/CompanyAddresses';
import CompanyAddress from './component/control-panel/company/addresses/CompanyAddress';
import CompanyAttachmentsArea from './component/control-panel/company/attachments/CompanyAttachmentsArea';

import VehicleList from './component/control-panel/vehicle/VehicleList';
import Vehicle from './component/control-panel/vehicle/Vehicle';
import VehicleForm from './component/control-panel/vehicle/VehicleForm';

import { StorageList } from './component/control-panel/storage/StorageList';
import StorageForm from './component/control-panel/storage/StorageForm';
import Storage from './component/control-panel/storage/Storage';
import StorageContacts from './component/control-panel/storage/contacts/StorageContacts';
import StorageContact from './component/control-panel/storage/contacts/StorageContact';

import TmcList from './component/control-panel/tmc/TmcList';
import Tmc from './component/control-panel/tmc/Tmc';
import TmcForm from './component/control-panel/tmc/TmcForm';
import { AssortmentList } from './component/control-panel/assortment/AssortmentList';
import AssortmentForm from './component/control-panel/assortment/AssortmentForm';
import Assortment from './component/control-panel/assortment/Assortment';

import TmcOperations from './component/storage/tmc-operation/TmcOperations';
import TmcOperation from './component/storage/tmc-operation/TmcOperation';
import TmcOperationForm from './component/storage/tmc-operation/TmcOperationForm';
import AssortmentOperations from './component/storage/assortment-operation/AssortmentOperations';
import AssortmentOperationForm from './component/storage/assortment-operation/AssortmentOperationForm';
import AssortmentOperation from './component/storage/assortment-operation/AssortmentOperation';
import ClientAccounts from './component/control-panel/client/accounts/ClientAccounts';
import ClientAccount from './component/control-panel/client/accounts/ClientAccount';
import CompanyAccount from './component/control-panel/company/accounts/CompanyAccount';
import CompanyAccounts from './component/control-panel/company/accounts/CompanyAccounts';
import Kpp from './component/runs/Kpp';
import StorageQuotes from "./component/storage/quotes/StorageQuotes";

import Driver from './component/control-panel/crmUser/driver/Driver';
import DriverForm from './component/control-panel/crmUser/driver/DriverForm';
import DriverList from './component/control-panel/crmUser/driver/DriverList';

import EdiMessageList from './component/quotes-edi/EdiMessageList';
import EdiComparison from './component/quotes-edi/EdiComparison';
import IndexNavigation from './component/layout/IndexNavigation';
import UserList from './component/control-panel/crmUser/all/UserList';
import UserForm from './component/control-panel/crmUser/all/UserForm';
import User from './component/control-panel/crmUser/all/User';
import { ClientAssortmentView, StorageAssemblyTable } from './component/storage/assembly-task/StorageAssemblyTable';

import ProductionLine from './component/control-panel/productionLine/ProductionLine';
import ProductionLineForm from './component/control-panel/productionLine/ProductionLineForm';
import ProductionLineList from './component/control-panel/productionLine/ProductionLineList';
import AssemblyPlan from './component/storage/assembly-plan/AssemblyPlan';
import { StorageDistributionTable } from './component/storage/distribution/StorageDistributionTable';
import { TalmanTable } from './component/runs/talman/TalmanTable';
import { TalmanForm } from './component/runs/talman/TalmanForm';
import EdiPartyList from './component/control-panel/settings/EdiPartyList';
import AuditList from './component/form/audit/AuditList';
import { AuditSource } from './model/audit/AuditSource';

export const router = createBrowserRouter([
    {
        path: PATH_BASE,
        element: <App/>,
        children: [
            {
                index: true,
                element: <IndexNavigation/>,
            },
            {
                path: PATH_QUOTES,
                element: <Outlet/>,
                children: [
                    {index: true, element: <Quotes/>},
                    {
                        path: `${PATH_QUOTE}?`,
                        element: <QuoteForm/>,
                        children: [
                            { index: true, element: <Quote /> },
                            {
                                path: PATH_QUOTE_HISTORY,
                                element: <AuditList
                                    source={AuditSource.QUOTE}
                                    sourceIdPathVariableName={PATH_VARIABLE_QUOTE_ID}
                                />
                            },
                        ],
                    },
                ],
            },
            {
                path: PATH_RUNS,
                element: <Outlet/>,
                children: [
                    {index: true, element: <Runs/>},
                    {
                        path: `${PATH_RUN}?`,
                        element: <RunForm/>,
                        children: [
                            { index: true, element: <Run /> },
                            { path: PATH_RUN_MAP, element: <RunMap /> },
                            { path: PATH_RUN_ATTACHMENTS, element: <RunAttachmentsArea /> },
                            {
                                path: `${PATH_RUN_TALMANS}?`,
                                element: <TalmanTable/>,
                                children: [
                                    {
                                        path: `${PATH_RUN_TALMAN}?`,
                                        element: <TalmanForm/>,
                                        children: [
                                            {index: true, element: <Outlet/>},
                                        ]
                                    },
                                ],
                            },
                            {
                                path: PATH_RUN_HISTORY,
                                element: <AuditList
                                    source={AuditSource.RUN}
                                    sourceIdPathVariableName={PATH_VARIABLE_RUN_ID}
                                />
                            },
                        ],
                    },
                ],
            },
            {
                path: PATH_KPP,
                element: <Outlet/>,
                children: [
                    {index: true, element: <Kpp/>},
                ],
            },
            {
                path: PATH_EDI_MESSAGES,
                element: <EdiMessageList/>,
                children: [
                    {
                        path: PATH_EDI_MESSAGE_TO_QUOTE,
                        element: <EdiComparison/>
                    },

                ],
            },
            {
                path: PATH_STORAGE,
                children: [
                    {index: true, path: `${PATH_STORAGE_QUOTES}`, element: <StorageQuotes/>},
                    {
                        path: `${PATH_STORAGE_ASSEMBLY_PLAN}?`,
                        element: <AssemblyPlan/>,
                    },
                    {
                        path: `${PATH_STORAGE_ASSEMBLY}?`,
                        element: <StorageAssemblyTable/>,
                        children: [
                            {index: true, element: <Outlet/>},
                            {
                                path: PATH_STORAGE_ASSEMBLY_ASSORTMENT,
                                children: [
                                    { index: true, element: <Outlet /> },
                                    { path: PATH_STORAGE_ASSEMBLY_ASSORTMENT_CLIENT_VIEW, element: <ClientAssortmentView /> }
                                ]}

                        ]
                    },
                    {
                        path: `${PATH_STORAGE_DISTRIBUTION}?`,
                        element: <StorageDistributionTable />,
                        children: [
                            {index: true, element: <Outlet/>},
                            {
                                path: PATH_STORAGE_DISTRIBUTION_ASSORTMENT,
                                children: [
                                    { index: true, element: <Outlet /> },
                                    {
                                        path: PATH_STORAGE_DISTRIBUTION_ASSORTMENT_CLIENT,
                                        children: [
                                            {index: true, element: <Outlet/>},
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: PATH_STORAGE_LOADING,
                        element: <Outlet />,
                        children: [{ index: true, element: <StorageLoading /> }],
                    },
                    {
                        path: PATH_STORAGE_ASSORTMENT_OPERATIONS,
                        element: <Outlet/>,
                        children: [
                            {index: true, element: <AssortmentOperations/>},
                            {
                                path: `${PATH_STORAGE_ASSORTMENT_OPERATION}?`,
                                element: <AssortmentOperationForm/>,
                                children: [
                                    {index: true, element: <AssortmentOperation/>},
                                    {
                                        path: PATH_STORAGE_ASSORTMENT_OPERATION_HISTORY,
                                        element: <AuditList
                                            source={AuditSource.ASSORTMENT_OPERATION}
                                            sourceIdPathVariableName={PATH_VARIABLE_ASSORTMENT_OPERATION_ID}
                                        />
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: PATH_STORAGE_TMC_OPERATIONS,
                        element: <Outlet/>,
                        children: [
                            {index: true, element: <TmcOperations/>},
                            {
                                path: `${PATH_STORAGE_TMC_OPERATION}?`,
                                element: <TmcOperationForm/>,
                                children: [
                                    {index: true, element: <TmcOperation/>},
                                    {
                                        path: PATH_STORAGE_TMC_OPERATION_HISTORY,
                                        element: <AuditList
                                            source={AuditSource.TMC_OPERATION}
                                            sourceIdPathVariableName={PATH_VARIABLE_TMC_OPERATION_ID}
                                        />
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: PATH_CP_STORAGE_CHARTS,
                        element: <Outlet />,
                        children: [
                            { path: PATH_CP_STORAGE_STATE_CHART, element: <StorageStateChart /> },
                        ],
                    },
                ],
            },
            {
                path: PATH_CP,
                children: [
                    {
                        path: PATH_CP_CLIENTS,
                        element: <Outlet/>,
                        children: [
                            {index: true, element: <Clients/>},
                            {
                                path: `${PATH_CP_CLIENT}?`,
                                element: <ClientForm/>,
                                children: [
                                    {index: true, element: <Client/>},
                                    {
                                        path: PATH_CP_CLIENT_CONTRACTS,
                                        element: <ClientContracts/>,
                                        children: [
                                            {
                                                path: `${PATH_CP_CLIENT_CONTRACT}?`,
                                                element: <ClientContract/>,
                                            },
                                        ],
                                    },
                                    {
                                        path: PATH_CP_CLIENT_CONTACTS,
                                        element: <ClientContacts/>,
                                        children: [
                                            {
                                                path: `${PATH_CP_CLIENT_CONTACT}?`,
                                                element: <ClientContact/>,
                                            },
                                        ],
                                    },
                                    {
                                        path: PATH_CP_CLIENT_ADDRESSES,
                                        element: <ClientAddresses/>,
                                        children: [
                                            {
                                                path: `${PATH_CP_CLIENT_ADDRESS}?`,
                                                element: <ClientAddress/>,
                                            },
                                        ],
                                    },
                                    {
                                        path: PATH_CP_CLIENT_ACCOUNTS,
                                        element: <ClientAccounts/>,
                                        children: [
                                            {
                                                path: `${PATH_CP_CLIENT_ACCOUNT}?`,
                                                element: <ClientAccount/>,
                                            },
                                        ],
                                    },
                                    {
                                        path: PATH_CP_CLIENT_ASSORTMENT,
                                        element: <ClientAssortment/>,
                                        children: [
                                            {
                                                path: `${PATH_CP_CLIENT_ASSORTMENT_FORM}?`,
                                                element: <ClientAssortmentForm/>,
                                            },
                                        ],
                                    },
                                    {
                                        path: PATH_CP_CLIENT_CONSIGNEES,
                                        element: <ClientConsignees/>,
                                        children: [
                                            {
                                                path: `${PATH_CP_CLIENT_CONSIGNEE}?`,
                                                element: <ClientConsignee/>,
                                            },
                                        ],
                                    },
                                    {
                                        path: PATH_CP_CLIENT_HISTORY,
                                        element: <AuditList
                                            source={AuditSource.CLIENT}
                                            sourceIdPathVariableName={PATH_VARIABLE_CLIENT_ID}
                                        />
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: PATH_CP_COMPANIES,
                        element: <Outlet/>,
                        children: [
                            {index: true, element: <Companies/>},
                            {
                                path: `${PATH_CP_COMPANY}?`,
                                element: <CompanyForm/>,
                                children: [
                                    {index: true, element: <Company/>},
                                    {
                                        path: PATH_CP_COMPANY_ADDRESSES,
                                        element: <CompanyAddresses/>,
                                        children: [
                                            {
                                                path: `${PATH_CP_COMPANY_ADDRESS}?`,
                                                element: <CompanyAddress/>,
                                            },
                                        ],
                                    },
                                    {
                                        path: PATH_CP_COMPANY_ACCOUNTS,
                                        element: <CompanyAccounts/>,
                                        children: [
                                            {
                                                path: `${PATH_CP_COMPANY_ACCOUNT}?`,
                                                element: <CompanyAccount/>,
                                            },
                                        ],
                                    },
                                    {
                                        path: PATH_CP_COMPANY_FACSIMILE,
                                        element: <CompanyAttachmentsArea/>,
                                    },
                                    {
                                        path: PATH_CP_COMPANY_HISTORY,
                                        element: <AuditList
                                            source={AuditSource.CLIENT}
                                            sourceIdPathVariableName={PATH_VARIABLE_COMPANY_ID}
                                        />
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: PATH_CP_USERS,
                        element: <Outlet/>,
                        children: [
                            {index: true, element: <UserList/>},
                            {
                                path: `${PATH_CP_USER}?`,
                                element: <UserForm/>,
                                children: [
                                    {index: true, element: <User/>},
                                    {
                                        path: PATH_CP_USER_HISTORY,
                                        element: <AuditList
                                            source={AuditSource.CRM_USER}
                                            sourceIdPathVariableName={PATH_VARIABLE_USER_ID}
                                        />
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: PATH_CP_VEHICLES,
                        element: <Outlet/>,
                        children: [
                            {index: true, element: <VehicleList/>},
                            {
                                path: `${PATH_CP_VEHICLE}?`,
                                element: <VehicleForm/>,
                                children: [
                                    {index: true, element: <Vehicle/>},
                                    {
                                        path: PATH_CP_VEHICLE_HISTORY,
                                        element: <AuditList
                                            source={AuditSource.VEHICLE}
                                            sourceIdPathVariableName={PATH_VARIABLE_VEHICLE_ID}
                                        />
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: PATH_CP_DRIVERS,
                        element: <Outlet/>,
                        children: [
                            {index: true, element: <DriverList/>},
                            {
                                path: `${PATH_CP_DRIVER}?`,
                                element: <DriverForm/>,
                                children: [
                                    {index: true, element: <Driver/>},
                                    {
                                        path: PATH_CP_DRIVER_HISTORY,
                                        element: <AuditList
                                            source={AuditSource.CRM_USER}
                                            sourceIdPathVariableName={PATH_VARIABLE_DRIVER_ID}
                                        />
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: PATH_CP_TMCS,
                        element: <Outlet/>,
                        children: [
                            {index: true, element: <TmcList/>},
                            {
                                path: `${PATH_CP_TMC}?`,
                                element: <TmcForm/>,
                                children: [
                                    {index: true, element: <Tmc/>},
                                    {
                                        path: PATH_CP_TMC_HISTORY,
                                        element: <AuditList
                                            source={AuditSource.TMC}
                                            sourceIdPathVariableName={PATH_VARIABLE_TMC_ID}
                                        />
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: PATH_CP_DICTIONARY_LIST,
                        element: <Outlet/>,
                        children: [
                            {index: true, element: <DictionaryList/>},
                            {
                                path: `${PATH_CP_DICTIONARY_EDIT}?`,
                                element: <DictionaryForm/>,
                            },
                        ],
                    },
                    {
                        path: PATH_CP_ASSORTMENTS,
                        element: <Outlet/>,
                        children: [
                            {index: true, element: <AssortmentList/>},
                            {
                                path: `${PATH_CP_ASSORTMENT}?`,
                                element: <AssortmentForm/>,
                                children: [
                                    {index: true, element: <Assortment/>},
                                    {
                                        path: PATH_CP_ASSORTMENT_HISTORY,
                                        element: <AuditList
                                            source={AuditSource.ASSORTMENT}
                                            sourceIdPathVariableName={PATH_VARIABLE_ASSORTMENT_ID}
                                        />
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: PATH_CP_PRODUCTION_LINES,
                        element: <Outlet/>,
                        children: [
                            {index: true, element: <ProductionLineList/>},
                            {
                                path: `${PATH_CP_PRODUCTION_LINE}?`,
                                element: <ProductionLineForm/>,
                                children: [
                                    {index: true, element: <ProductionLine/>},
                                    {
                                        path: PATH_CP_PRODUCTION_LINE_HISTORY,
                                        element: <AuditList
                                            source={AuditSource.PRODUCTION_LINE}
                                            sourceIdPathVariableName={PATH_VARIABLE_PRODUCTION_LINE_ID}
                                        />
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: PATH_CP_STORAGES,
                        element: <Outlet/>,
                        children: [
                            {index: true, element: <StorageList/>},
                            {
                                path: `${PATH_CP_STORAGE}?`,
                                element: <StorageForm/>,
                                children: [
                                    {index: true, element: <Storage/>},
                                    {
                                        path: PATH_CP_STORAGE_CONTACTS,
                                        element: <StorageContacts/>,
                                        children: [
                                            {
                                                path: `${PATH_CP_STORAGE_CONTACT}?`,
                                                element: <StorageContact/>
                                            }
                                        ],
                                    },
                                    {
                                        path: PATH_CP_STORAGE_HISTORY,
                                        element: <AuditList
                                            source={AuditSource.STORAGE}
                                            sourceIdPathVariableName={PATH_VARIABLE_STORAGE_ID}
                                        />
                                    },
                                ],
                            },

                        ],
                    },
                    {
                        path: PATH_CP_INTEGRATIONS,
                        element: <Outlet/>,
                        children: [
                            {
                                path: `${PATH_CP_INTEGRATIONS_DADATA}?`,
                                element: <Dadata/>,
                            },
                            {
                                path: `${PATH_CP_INTEGRATIONS_EDI}?`,
                                element: <Outlet/>,
                                children: [
                                    {path: PATH_CP_INTEGRATIONS_EDI_SETTINGS, element: <EdiSettings />},
                                    {path: PATH_CP_INTEGRATIONS_EDI_PARTY, element: <EdiPartyList />}
                                ]
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: PATH_LOGIN,
        element: <Login/>,
    },
]);
