export enum TmcField {
    ID = 'id',
    NAME = 'name',
    UNIT = 'unit',
    QUANTITY = 'quantity',
    AUTOMATIC = 'automatic',
    DEDUCT_RATE = 'deductRate',
    CATEGORY = 'category',
    LINKED_TMC = 'linkedTmc',
    TYPE = 'type',
}
