import React, { MouseEvent, useCallback, useContext, useEffect, useMemo } from 'react';
import { ClientAssortmentModel } from '../../../model/assortment';
import { DEFAULT_OPTION, Option } from '../../control/option';
import { ListAssortments, ListAllCompanyAssortments } from '../../form/list/ListAssortments';
import ListTmc from '../../form/list/ListTmc';
import { Config } from '../../table/config';
import './shipment-details.scss';
import { FormContext, useFieldValue, useSetFieldValue, validateField } from '../../form/DwForm';
import {
    calcCargoPrice,
    calcTotalCost,
    ShipmentDetailModel,
    ShipmentDetailType,
    ShipmentDetailTypeNames,
} from '../model/shipmentDetailModel';
import { QuoteModel } from '../model/quoteModel';
import util from '../../../common/util';
import { EditableDwTable } from '../../table/EditableDwTable';
import { RemoveButton } from '../../table/RemoveButton';
import { ShiftButtons } from '../../table/ShiftButtons';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import clientAssortments from '../../../api/clientAssortments';
import { QuoteField } from '../quoteField';
import { ColumnType } from '../../table/columnType';
import { useIsMounted } from '../../../common/isMounted';
import { Unit } from '../../../model/enums/Unit';
import ShipmentDetailsBtns from './ShipmentDetailsBtns';
import { ShipmentDetailField } from './shipmentDetailField';

type Props = {
    id: string;
};

const useFormConfig = (
    id: string,
    quoteModel: any,
    onShift: (e: MouseEvent, row: ShipmentDetailModel, isUp: boolean) => void,
    onAddDefaultSD: (e: React.UIEvent<HTMLElement> | React.KeyboardEvent) => void,
    onDelete: (e: MouseEvent, row: ShipmentDetailModel) => void,
    onClientAssortmentChange: (newValue: SingleValue<any>, rowNum: number) => Promise<any>,
    onWeightChange: (newValue: number, rowNum: number) => Promise<any>,
    onQuantityChange: (newValue: number, rowNum: number) => Promise<any>,
    onAddAssortmentBased: () => void,
    onAddTmcBased: () => void
) =>
    useMemo(
        () =>
            Config.builder<ShipmentDetailModel>()
                .column((builder) => builder.position('#').cls('id'))
                .column((builder) =>
                    builder
                        .text('Тип груза', ShipmentDetailField.ASSORTMENT_OR_TMC)
                        .getter((model) =>
                            quoteModel?.isDomesticQuote && model.tmc
                                ? ShipmentDetailTypeNames.get(ShipmentDetailType.TMC)
                                : ShipmentDetailTypeNames.get(ShipmentDetailType.ASSORTMENT)
                        )
                        .cls('text')
                )
                .column((builder) =>
                    builder
                        .label('Наименование Заказчика')
                        .field(ShipmentDetailField.CLIENT_ASSORTMENT)
                        .type(ColumnType.CLIENT_CONSIGNEE_ASSORTMENT)
                        .editable()
                        .editableColProps(() => {
                            return {
                                clientId: quoteModel.client?.value,
                                consigneeId: quoteModel.consignee?.value,
                            };
                        })
                        .onChangeRow(onClientAssortmentChange)
                        .cls('assortment')
                )
                .column((builder) =>
                    builder
                        .label('Наименование склада')
                        .getter((model) =>
                            quoteModel?.isDomesticQuote && model.tmc
                                ? model.tmc?.label
                                : model.companyAssortment?.label
                        )
                        .editor((model) => {
                            if (quoteModel?.isDomesticQuote) {
                                return model.tmc ? (
                                    <ListTmc id={ShipmentDetailField.TMC} />
                                ) : (
                                    <ListAssortments id={ShipmentDetailField.COMPANY_ASSORTMENT} />
                                );
                            } else {
                                return (
                                    <ListAllCompanyAssortments
                                        id={ShipmentDetailField.COMPANY_ASSORTMENT}
                                        clientId={
                                            quoteModel.consignee?.value
                                                ? quoteModel.consignee.value
                                                : quoteModel.client?.value
                                        }
                                    />
                                );
                            }
                        })
                        .editable()
                        .cls('assortment')
                )
                .column((builder) =>
                    builder.money('Закуп', ShipmentDetailField.BUY_PRICE).editable().cls('money')
                )
                .column((builder) =>
                    builder.boolean('Сетка', ShipmentDetailField.NET_PACK).editable().cls('boolean')
                )
                .column((builder) =>
                    builder.integer('Квант', ShipmentDetailField.QUANTUM).editable().cls('number')
                )
                .column((builder) =>
                    builder
                        .label('Ед. изм.')
                        .field(ShipmentDetailField.UNIT)
                        .type(ColumnType.UNIT)
                        .editable()
                        .cls('option')
                )
                .column((builder) =>
                    builder
                        .integer('Кол-во ед. изм.', ShipmentDetailField.QUANTITY)
                        .editable()
                        .onChangeRow(onQuantityChange)
                        .cls('number')
                )
                .column((builder) =>
                    builder
                        .label('Упаковка')
                        .field(ShipmentDetailField.PACKAGE_TMC)
                        .type(ColumnType.TMC_PACKAGE_CATEGORY)
                        .editable()
                        .cls('option')
                )
                .column((builder) =>
                    builder
                        .integer('Влож.', ShipmentDetailField.PACKAGE_PER_TARE)
                        .editable()
                        .cls('number')
                )
                .column((builder) =>
                    builder.money('Цена', ShipmentDetailField.SELL_PRICE).editable().cls('money')
                )
                .column((builder) =>
                    builder
                        .integer('Вес', ShipmentDetailField.WEIGHT)
                        .editable()
                        .onChangeRow(onWeightChange)
                        .cls('number')
                )
                .column((builder) =>
                    builder
                        .label('Тара')
                        .field(ShipmentDetailField.TARE_TMC)
                        .type(ColumnType.TMC_TARE_CATEGORY)
                        .editable()
                        .nullable(true)
                        .cls('option')
                )
                .column((builder) =>
                    builder
                        .integer('Кол-во тары', ShipmentDetailField.TARE_QUANTITY)
                        .editable()
                        .cls('number')
                )
                .column((builder) =>
                    builder
                        .money('Стоимость', ShipmentDetailField.TOTAL_COST)
                        .getter((model) => calcTotalCost(model))
                        .cls('money')
                )
                .column((builder) =>
                    builder
                        .integer('Вес загруж', ShipmentDetailField.WEIGHT_LOADED)
                        .editable()
                        .cls('number')
                )
                .column((builder) =>
                    builder
                        .integer('Вес выгруз', ShipmentDetailField.WEIGHT_UNLOADED)
                        .editable()
                        .cls('number')
                )
                .column((builder) =>
                    builder
                        .integer('Вес принят', ShipmentDetailField.WEIGHT_ACCEPTED)
                        .editable()
                        .cls('number')
                )
                .column((builder) =>
                    builder
                        .text('Темп. режим', ShipmentDetailField.TEMPERATURE_REGIME)
                        .editable()
                        .cls('text')
                )
                .column((builder) =>
                    builder
                        .text('Дополнительно', ShipmentDetailField.DESCRIPTION)
                        .editable()
                        .cls('text')
                )
                .column((builder) =>
                    builder
                        .getter((model) => (
                            <div className='btn-group' role='group'>
                                <ShiftButtons
                                    onClick={(e: MouseEvent, isUp: boolean) =>
                                        onShift(e, model, isUp)
                                    }
                                    isFirst={model.position === 0}
                                    isLast={model.position === quoteModel[id]?.length - 1}
                                />
                                <RemoveButton onClick={(e: MouseEvent) => onDelete(e, model)} />
                            </div>
                        ))
                        .cls('text-center actions')
                        .width('100px')
                )
                .id(id)
                .captionControl(() => (
                    <ShipmentDetailsBtns
                        id={id}
                        onAddDefaultSD={onAddDefaultSD}
                        onAddAssortmentBased={onAddAssortmentBased}
                        onAddTmcBased={onAddTmcBased}
                    />
                ))
                .defaultHidden(getDefaultHidden(quoteModel))
                .build(),
        [
            id,
            quoteModel,
            onClientAssortmentChange,
            onQuantityChange,
            onWeightChange,
            onShift,
            onDelete,
            onAddDefaultSD,
            onAddAssortmentBased,
            onAddTmcBased,
        ]
    );

const getDefaultHidden = (quoteModel: QuoteModel): string[] => {
    const hiddenFields: ShipmentDetailField[] = [];

    if (!quoteModel.carrierThirdParty) {
        hiddenFields.push(ShipmentDetailField.BUY_PRICE);
    }

    if (quoteModel.isDomesticQuote) {
        hiddenFields.push(
            ShipmentDetailField.CLIENT_ASSORTMENT,
            ShipmentDetailField.BUY_PRICE,
            ShipmentDetailField.NET_PACK,
            ShipmentDetailField.QUANTUM,
            ShipmentDetailField.SELL_PRICE,
            ShipmentDetailField.BUY_PRICE,
            ShipmentDetailField.UNIT,
            ShipmentDetailField.QUANTITY,
            ShipmentDetailField.TOTAL_COST
        );
    } else {
        hiddenFields.push(ShipmentDetailField.ASSORTMENT_OR_TMC);
    }

    return hiddenFields;
};

const ShipmentDetailsTable: React.FC<Props> = ({ id }) => {
    const context = useContext(FormContext);
    const model = context.state.model as QuoteModel;
    const setValue = useSetFieldValue();
    const fieldConfig = context.state.config.field(id);
    const shipmentDetailsValue = useFieldValue(QuoteField.SHIPMENT_DETAILS);
    const shipmentDetailsData: ShipmentDetailModel[] = useMemo(
        () => shipmentDetailsValue ?? [],
        [shipmentDetailsValue]
    );
    const valueClient = useFieldValue(QuoteField.CLIENT);

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted) {
            validateField(context, fieldConfig, model).then();
        }
    }, [shipmentDetailsData]);

    const onChange = useCallback(
        (details: ShipmentDetailModel[]) => {
            setValue(id, details);
        },
        [id, setValue]
    );

    const addShipmentDetail = useCallback(
        (useAssortment: boolean): void => {
            const shipmentDetail: Partial<ShipmentDetailModel> = {
                position: shipmentDetailsData.length,
                ...(useAssortment
                    ? { companyAssortment: DEFAULT_OPTION }
                    : { tmc: DEFAULT_OPTION }),
            };

            onChange([...shipmentDetailsData, shipmentDetail as ShipmentDetailModel]);
        },
        [shipmentDetailsData, onChange]
    );

    const onAddDefaultSD = useCallback(
        (e: React.UIEvent<HTMLElement> | React.KeyboardEvent): void => {
            e.preventDefault();
            addShipmentDetail(true);
        },
        [addShipmentDetail]
    );

    const onAddAssortmentBased = useCallback(() => addShipmentDetail(true), [addShipmentDetail]);
    const onAddTmcBased = useCallback(() => addShipmentDetail(false), [addShipmentDetail]);

    const onDelete = useCallback(
        (e: MouseEvent, row: ShipmentDetailModel) => {
            e.preventDefault();
            const shipmentDetails = [...shipmentDetailsData];
            shipmentDetails.splice(row.position, 1);
            shipmentDetails.filter((p) => p.position > row.position).forEach((d) => d.position--);
            onChange(shipmentDetails);
        },
        [shipmentDetailsData, onChange]
    );

    const onShift = useCallback(
        (e: MouseEvent, row: ShipmentDetailModel, isUp: boolean) => {
            e.preventDefault();
            const shipmentDetails = [...shipmentDetailsData];
            const signedOne = isUp ? -1 : 1;
            const rowToExchange = shipmentDetails[row.position + signedOne];
            row.position += signedOne;
            rowToExchange.position -= signedOne;
            shipmentDetails.sort((a, b) => (a.position > b.position ? 1 : -1));
            onChange(shipmentDetails);
        },
        [shipmentDetailsData, onChange]
    );

    const onClientAssortmentChange = useCallback(
        (newValue: SingleValue<any>, rowNum: number) =>
            clientAssortments.get(newValue?.value).then((assortment) => {
                const shipmentDetails = [...shipmentDetailsData];
                const shipmentDetail = shipmentDetails[rowNum];
                shipmentDetail.clientAssortment = {
                    value: assortment.id,
                    label: assortment.clientName,
                };
                shipmentDetail.companyAssortment = assortment.name;
                shipmentDetail.netPack = assortment.useNet;
                shipmentDetail.quantum = assortment.weightMin;
                shipmentDetail.packagePerTare = assortment.boxes;
                shipmentDetail.packageTmc = assortment.packageTmc;
                shipmentDetail.tareTmc = assortment.tareTmc;
                shipmentDetail.temperatureRegime = assortment.temperatureRegime;
                shipmentDetail.unit = assortment.unit;
                onChange(shipmentDetails);
            }),
        [valueClient, shipmentDetailsData, onChange]
    );

    const onWeightChange = useCallback(
        async (newValue: number, rowNum: number) => {
            if (newValue <= 0) {
                return Promise.resolve();
            }

            const shipmentDetails: ShipmentDetailModel[] = [...shipmentDetailsData];
            const shipmentDetail: ShipmentDetailModel = shipmentDetails[rowNum];

            const clientId = valueClient?.value;
            const clientAssortment: Option = shipmentDetailsData[rowNum]?.clientAssortment;
            const hasClientAssortment = clientAssortment?.value && clientAssortment?.label;

            if (!clientId || !hasClientAssortment) {
                return Promise.resolve();
            }

            const fetchClientAssortment: Promise<ClientAssortmentModel> = clientAssortments.get(
                clientAssortment?.value
            );

            const calculateAndSetValues = (assortment: ClientAssortmentModel) => {
                if (assortment.weightAvg > 0) {
                    shipmentDetail.tareQuantity = Math.ceil(newValue / assortment.weightAvg);
                }
                onChange(shipmentDetails);
            };

            return fetchClientAssortment.then(calculateAndSetValues);
        },
        [valueClient, shipmentDetailsData, onChange]
    );

    const onQuantityChange = useCallback(
        async (newValue: number, rowNum: number) => {
            if (newValue <= 0) {
                return Promise.resolve();
            }

            const shipmentDetails: ShipmentDetailModel[] = [...shipmentDetailsData];
            const shipmentDetail: ShipmentDetailModel = shipmentDetails[rowNum];
            const unit: Option = shipmentDetail.unit;

            const clientId = valueClient?.value;
            const clientAssortment: Option = shipmentDetailsData[rowNum]?.clientAssortment;
            const hasClientAssortment = clientAssortment?.value && clientAssortment?.label;

            if (!clientId || !hasClientAssortment) {
                return Promise.resolve();
            }

            const fetchClientAssortment: Promise<ClientAssortmentModel> = clientAssortments.get(
                clientAssortment?.value
            );

            const calculateAndSetValues = (assortment: ClientAssortmentModel) => {
                if (unit?.value === Unit.PIECE) {
                    const weight: number =
                        (((assortment.weightFrom ?? 0) + (assortment.weightTo ?? 0)) / 2) *
                        newValue;
                    shipmentDetail.weight = Number((weight ?? 0).toFixed(2));
                } else if (unit?.value === Unit.KILO) {
                    shipmentDetail.weight = newValue;
                }

                if (assortment.weightAvg > 0 && shipmentDetail.weight > 0) {
                    shipmentDetail.tareQuantity = Math.ceil(
                        shipmentDetail.weight / assortment.weightAvg
                    );
                }
                onChange(shipmentDetails);
            };

            return fetchClientAssortment.then(calculateAndSetValues);
        },
        [valueClient, shipmentDetailsData, onChange]
    );

    const config = useFormConfig(
        id,
        model,
        onShift,
        onAddDefaultSD,
        onDelete,
        onClientAssortmentChange,
        onWeightChange,
        onQuantityChange,
        onAddAssortmentBased,
        onAddTmcBased
    );

    const renderSummaryRow = () => {
        const columns = config.columns.filter(
            (col) => config.defaultHidden.indexOf(col.field) === -1
        );
        const colsToSpanBefore = columns.map((c) => c.field).indexOf(ShipmentDetailField.WEIGHT);
        const colsToSpanAfter =
            columns.length - columns.map((c) => c.field).indexOf(ShipmentDetailField.TOTAL_COST);
        return (
            <tr className={'summary-row'}>
                <td colSpan={colsToSpanBefore}>Итого</td>
                <td>{util.sumOverObjectProps(shipmentDetailsData, ShipmentDetailField.WEIGHT)}</td>
                <td />
                <td>{util.sumOverObjectProps(shipmentDetailsData, ShipmentDetailField.TARE_QUANTITY)}</td>
                <td>{calcCargoPrice(shipmentDetailsData)}</td>
                <td colSpan={colsToSpanAfter} />
            </tr>
        );
    };

    return (
        <EditableDwTable header='Грузы' config={config}>
            {renderSummaryRow()}
        </EditableDwTable>
    );
};

export default ShipmentDetailsTable;
