export enum ContractField {
    ID = 'id',
    TYPE = 'type',
    STATUS = 'status',
    NUMBER = 'number',
    SIGNED = 'signed',
    BEGIN_DATE = 'beginDate',
    END_DATE = 'endDate',
    MAIN = 'main',
    HAS_ADDITIONAL = 'hasAdditional',
    DOCUMENTS = 'documents',
    PAYMENT_DELAY = 'paymentDelay',
    DELAY_TYPE = 'delayType',
    PAYMENT_TYPE = 'paymentType',
    PAYMENT_METHOD = 'paymentMethod',
    TTN_DAYS = 'ttnDays',
    CURRENCY = 'currency',
    REQUIREMENTS = 'requirements',
    DOWNTIME_COST = 'downtimeCost',
    
    COMPANY = 'company',
    COMPANY_HEAD_POSITION = 'companyHeadPosition',
    COMPANY_HEAD_GENITIVE = 'companyHeadGenitive',
    COMPANY_BASIS = 'companyBasis',
    COMPANY_ACCOUNT = 'companyAccount',
    
    CLIENT = 'client',
    CLIENT_HEAD_POSITION = 'clientHeadPosition',
    CLIENT_HEAD_GENITIVE = 'clientHeadGenitive',
    CLIENT_BASIS = 'clientBasis',
    CLIENT_ACCOUNT = 'clientAccount',
    
    ATTACHMENTS = 'attachments',    
}