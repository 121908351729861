import { get, post } from './rest';
import { ApiResponse } from './apiResponse';
import { RunModel } from '../component/runs/model/runModel';
import { Option } from '../component/control/option';
import { RunOptionsRequest } from '../component/runs/model/runOptionsRequest';
import { ListRequest } from '../component/table/listRequest';
import { PagedResponse } from './pagedResponse';
import { RunListModel } from '../component/runs/model/runListModel';
import { DriverVehiclesModel } from '../model/vehicle/driverVehiclesModel';
import { BaseOptionRequest } from './baseOptionRequest';
import { ListAttachmentModel } from '../model/attachment';

const PATH_BASE = 'runs';

const runs = {
    get: (id: number) => get<RunModel>(`${PATH_BASE}/${id}`),
    save: (model: RunModel) => post<ApiResponse>(PATH_BASE, model),

    list: (model: ListRequest, domestic: boolean) =>
        post<PagedResponse<RunListModel>>(`${PATH_BASE}/list?domestic=${domestic}`, model),
    kppRunList: (model: ListRequest) =>
        post<PagedResponse<RunListModel>>(`${PATH_BASE}/kpp-list`, model),
    loadingRunList: (model: ListRequest) =>
        post<PagedResponse<RunListModel>>(`${PATH_BASE}/loading-list`, model),

    quoteOptions: (request: RunOptionsRequest) =>
        post<Option[]>(`${PATH_BASE}/options/quote`, request),
    unlinkQuote: (quoteId: number) => get<ApiResponse>(`${PATH_BASE}/quote/${quoteId}/unlink`),
    assortmentOptions: (request: BaseOptionRequest) =>
        post<Option[]>(`${PATH_BASE}/options/assortment`, request),
    lastVehiclesByDriver: (driverId: number) =>
        post<DriverVehiclesModel>(`${PATH_BASE}/last-vehicles-by-driver`, driverId),

    saveAttachments: (runId: number, model: ListAttachmentModel) =>
        post<ApiResponse>(`${PATH_BASE}/${runId}/attachments`, model),
    getAttachments: (runId: number) =>
        get<ListAttachmentModel>(`${PATH_BASE}/${runId}/attachments`),
};

export default runs;
