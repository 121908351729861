import React from 'react';
import { AttachmentType } from '../../../model/attachment';
import { ClientType } from '../../../model/client/clientModel';
import { DictionaryType } from '../../control/option';
import AttachmentsArea from '../../form/attachments/AttachmentsArea';
import CheckboxInput from '../../form/CheckboxInput';
import DtPicker from '../../form/DtPicker';
import { useFieldValue } from '../../form/DwForm';
import Options from '../../form/Options';
import StringInput from '../../form/StringInput';
import TextInput from '../../form/TextInput';
import { VehicleField } from './vehicleField';
import UserClients from '../../form/UserClients';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';

const Vehicle: React.FC = () => {
    const id = useFieldValue(VehicleField.ID);

    return (
        <div className='card h-100'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-lg-3'>
                        VIN номер
                        <StringInput id={VehicleField.VIN} />
                    </div>

                    <div className='col-lg-3'>
                        Модель ТС
                        <StringInput id={VehicleField.MODEL} />
                    </div>

                    <div className='col-lg-3'>
                        Номер
                        <StringInput id={VehicleField.NUMBER} />
                    </div>

                    <div className='col-lg-3 d-flex align-items-center'>
                        <div className='custom-control custom-switch'>
                            <CheckboxInput
                                id={VehicleField.ALIEN}
                                label='Не РФ'
                                className='custom-control-input'
                            />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-3'>
                        Тип ТС
                        <Options
                            id={VehicleField.TYPE}
                            dictionaryType={DictionaryType.VEHICLE_TYPE}
                        />
                    </div>

                    <div className='col-lg-3'>
                        Тип кузова
                        <Options
                            id={VehicleField.CARCASS}
                            dictionaryType={DictionaryType.CARCASS}
                        />
                    </div>

                    <div className='col-lg-2'>
                        Кубатура
                        <StringInput id={VehicleField.VOLUME} />
                    </div>

                    <div className='col-lg-2'>
                        Тоннаж
                        <StringInput id={VehicleField.TONNAGE} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-3'>
                        Серия номер ПТС
                        <StringInput id={VehicleField.PTS_INFO} />
                    </div>

                    <div className='col-lg-3'>
                        Дата выдачи ПТС
                        <DtPicker id={VehicleField.PTS_DATE} />
                    </div>

                    <div className='col-lg-2'>
                        ФИО собственника
                        <StringInput id={VehicleField.OWNER} />
                    </div>

                    <div className='col-lg-2'>
                        Тип собственности
                        <Options
                            id={VehicleField.OWNERSHIP}
                            dictionaryType={DictionaryType.VEHICLE_OWNERSHIP}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-3'>
                        Контрагенты
                        <UserClients
                            id={VehicleField.CLIENTS}
                            clientTypes={[ClientType.CARRIER, ClientType.CLIENT_OR_CARRIER]}
                            isMulti
                        />
                    </div>

                    <div className='col-lg-2'>
                        Согласование
                        <Options
                            id={VehicleField.APPROVAL}
                            dictionaryType={DictionaryType.APPROVAL_STATUS}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-6'>
                        <AttachmentsArea
                            id={VehicleField.ATTACHMENTS}
                            attachmentType={AttachmentType.VEHICLE}
                            parentId={id}
                        />
                    </div>

                    <div className='col-lg-6 mt-2'>
                        Заметки
                        <TextInput id={VehicleField.COMMENT} rows={6} />
                    </div>
                </div>
            </div>

            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </div>
    );
};

export default Vehicle;
