import React from 'react';
import { generatePath } from 'react-router-dom';
import tmc from '../../../api/tmc';
import { PATH_CP_TMC, PATH_VARIABLE_TMC_ID } from '../../../routerPaths';
import { ColumnType } from '../../table/columnType';
import { Config } from '../../table/config';
import { DwTable } from '../../table/DwTable';
import type { TmcListModel } from '../../../model/tmc/tmcListModel';
import { TmcField } from './tmcField';

const TmcList: React.FC = () => {
    const config = Config.builder<TmcListModel>()
        .column((builder) => builder.id())
        .column((builder) => builder.text('Наименование ТМЦ', TmcField.NAME).sortable(false))
        .column((builder) =>
            builder
                .label('Единица измерения')
                .field(TmcField.UNIT)
                .type(ColumnType.UNIT)
                .sortable(false)
                .cls('unit')
        )
        .column((builder) => builder.integer('Кол-во', TmcField.QUANTITY))
        .column((builder) => builder.integer('Норма списания', TmcField.DEDUCT_RATE))
        .column((builder) =>
            builder
                .label('Категория')
                .field(TmcField.CATEGORY)
                .type(ColumnType.TMC_CATEGORY)
                .sortable(false)
        )
        .column((builder) =>
            builder.label('ТМЦ').field(TmcField.LINKED_TMC).type(ColumnType.TMC).sortable(false)
        )
        .column((builder) =>
            builder
                .label('Вид номенклатуры')
                .field(TmcField.TYPE)
                .type(ColumnType.TMC_TYPE)
                .sortable(false)
        )
        .column((builder) =>
            builder
                .boolean('Списывать автоматически', TmcField.AUTOMATIC)
                .sortable(false)
                .cls('boolean')
        )
        .outerFilter((builder) => builder.field('storage').type(ColumnType.STORAGE))
        .formUrl(generatePath(PATH_CP_TMC, { [PATH_VARIABLE_TMC_ID]: '' }))
        .load((ctx) => tmc.list(ctx.state.request))
        .id('tmc')
        .defaultHidden([])
        .build();

    return (
        <div className='tmc d-flex'>
            <DwTable header='ТМЦ' config={config} />
        </div>
    );
};

export default TmcList;
