import React, { useContext, useMemo } from 'react';
import { NavigateFunction, Outlet, generatePath } from 'react-router-dom';
import util from '../../../../common/util';
import { Config } from '../../../table/config';
import { DwTable, onRowDelete } from '../../../table/DwTable';
import { ClientFormContext } from '../ClientForm';
import { PATH_CP_CLIENT_ASSORTMENT_FORM } from '../../../../routerPaths';
import { ClientAssortmentField } from './clientAssortmentField';
import { ClientAssortmentListModel } from '../../../../model/assortment';
import clientAssortments from '../../../../api/clientAssortments';
import { RemoveButton } from '../../../table/RemoveButton';

const useTableConfig: (
    clientId: number
) => Config<ClientAssortmentListModel> = (clientId) =>
    useMemo(() => {
        return Config.builder<ClientAssortmentListModel>()
            .column((builder) =>
                builder
                    .text('Наименование контрагента', ClientAssortmentField.CLIENT_NAME)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder.text('Наименование склада', ClientAssortmentField.NAME).sortable(false).filterable(false)
            )
            .column((builder) =>
                builder
                    .text('Этикетка заказчика', ClientAssortmentField.CLIENT_LABEL)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder.text('Этикетка организации', ClientAssortmentField.LABEL).sortable(false).filterable(false)
            )
            .column((builder) =>
                builder.boolean('Сетка', ClientAssortmentField.USE_NET).sortable(false).filterable(false)
            )
            .column((builder) =>
                builder
                    .text('Начальный калибр', ClientAssortmentField.WEIGHT_FROM)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder.text('Конечный калибр', ClientAssortmentField.WEIGHT_TO).sortable(false).filterable(false)
            )
            .column((builder) =>
                builder.text('Ед. изм.', ClientAssortmentField.UNIT).sortable(false).filterable(false)
            )
            .column((builder) =>
                builder.text('Упаковка', ClientAssortmentField.PACKAGE_TMC).sortable(false).filterable(false)
            )
            .column((builder) =>
                builder.text('Квант', ClientAssortmentField.WEIGHT_MIN).sortable(false).filterable(false)
            )
            .column((builder) =>
                builder.text('Упаковка/Тара', ClientAssortmentField.BOXES).sortable(false).filterable(false)
            )
            .column((builder) =>
                builder.text('Тара', ClientAssortmentField.TARE_TMC).sortable(false).filterable(false)
            )
            .column((builder) =>
                builder
                    .boolean('Тальманская расписка', ClientAssortmentField.USE_TALMAN)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder
                    .text('Штрих-код единицы', ClientAssortmentField.UNIT_BARCODE)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder
                    .text('Штрих-код упаковки', ClientAssortmentField.PACKAGE_BARCODE)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder.text('Артикул', ClientAssortmentField.NUMBER).sortable(false).filterable(false)
            )
            .column((builder) =>
                builder
                    .boolean('Паспорт паллет', ClientAssortmentField.USE_PASSPORT)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder.text('Вес ед.', ClientAssortmentField.WEIGHT_AVG).sortable(false).filterable(false)
            )
            .column((builder) =>
                builder
                    .text('Темп. реж.', ClientAssortmentField.TEMPERATURE_REGIME)
                    .sortable(false)
                    .filterable(false)
            )

            .column((builder) =>
                builder
                    .cls('text-center')
                    .sortable(false)
                    .filterable(false)
                    .getter((model, _, refresh) => (
                        <RemoveButton
                            onClick={() =>
                                onRowDelete(refresh, () =>
                                    clientAssortments.delete(model.id)
                                )
                            }
                        />
                    ))
                    .width('45px')
            )

            .columnsSelector(false)
            .formUrl(
                generatePath(PATH_CP_CLIENT_ASSORTMENT_FORM, {
                    assortmentId: '',
                    clientId: util.toString(clientId),
                })
            )
            .load((ctx) => clientAssortments.list(ctx.state.request, clientId))
            .onClick((assortmentId: number, navigate: NavigateFunction) =>
                navigate(
                    generatePath(PATH_CP_CLIENT_ASSORTMENT_FORM, {
                        clientId: util.toString(clientId),
                        assortmentId: util.toString(assortmentId),
                    })
                )
            )
            .id(`client_assortment`)
            .build();
    }, [clientId]);

export const ClientAssortment: React.FC = () => {
    const {
        client: { id: clientId },
    } = useContext(ClientFormContext);
    const config = useTableConfig(clientId);

    return (
        <>
            <div className='contracts d-flex mb-3'>
                <DwTable header='Чек-лист' config={config} />
            </div>
            <Outlet />
        </>
    );
};
