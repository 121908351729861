import React, { useEffect, useState, useCallback } from 'react';
import quotes from '../../api/quotes';
import { QuoteModel } from '../quotes/model/quoteModel';
import { ShipmentDetailModel } from '../quotes/model/shipmentDetailModel';
import { ShipmentDetailField } from '../quotes/shipment-details/shipmentDetailField';

const SHOW_INFO_DELAY = 1000;

const FIELD_NAMES: Partial<Record<ShipmentDetailField, string>> = {
    [ShipmentDetailField.POSITION]: '#',
    [ShipmentDetailField.COMPANY_ASSORTMENT]: 'Наименование склада',
    [ShipmentDetailField.UNIT]: 'Ед. изм.',
    [ShipmentDetailField.QUANTITY]: 'Кол-во ед. изм.',
    [ShipmentDetailField.PACKAGE_TMC]: 'Упаковка',
    [ShipmentDetailField.PACKAGE_PER_TARE]: 'Влож.',
    [ShipmentDetailField.WEIGHT]: 'Вес',
};

const ALL_SHIPMENT_DETAIL_FIELDS: ShipmentDetailField[] = [
    ShipmentDetailField.POSITION,
    ShipmentDetailField.COMPANY_ASSORTMENT,
    ShipmentDetailField.UNIT,
    ShipmentDetailField.QUANTITY,
    ShipmentDetailField.PACKAGE_TMC,
    ShipmentDetailField.PACKAGE_PER_TARE,
    ShipmentDetailField.WEIGHT,
];

const DOMESTIC_SHIPMENT_DETAIL_FIELDS: ShipmentDetailField[] = [
    ShipmentDetailField.POSITION,
    ShipmentDetailField.COMPANY_ASSORTMENT,
    ShipmentDetailField.PACKAGE_TMC,
    ShipmentDetailField.PACKAGE_PER_TARE,
    ShipmentDetailField.WEIGHT,
];

const getFieldValue = (
    detail: ShipmentDetailModel,
    field: ShipmentDetailField,
    quoteModel: QuoteModel
) => {
    switch (field) {
        case ShipmentDetailField.POSITION:
            return detail.position++;
        case ShipmentDetailField.COMPANY_ASSORTMENT:
            return quoteModel?.isDomesticQuote && detail.tmc
                ? detail.tmc?.label
                : detail.companyAssortment?.label;
        case ShipmentDetailField.UNIT:
            return detail.unit?.label;
        case ShipmentDetailField.QUANTITY:
            return detail.quantity;
        case ShipmentDetailField.PACKAGE_TMC:
            return detail.packageTmc?.label;
        case ShipmentDetailField.PACKAGE_PER_TARE:
            return detail.packagePerTare;
        case ShipmentDetailField.WEIGHT:
            return detail.weight;
        default:
            return null;
    }
};

type Props = {
    id: number;
    showInfo: boolean;
};

const ShipmentDetailsInfoTable: React.FC<Props> = ({ id, showInfo }) => {
    const [dataModel, setDataModel] = useState<QuoteModel | null>(null);

    useEffect(() => {
        if (showInfo) {
            const timer = setTimeout(async () => {
                const data = await quotes.get(id);
                if (showInfo) {
                    setDataModel(data);
                }
            }, SHOW_INFO_DELAY);

            return () => {
                clearTimeout(timer);
                setDataModel(null);
            };
        }
    }, [id, showInfo]);

    const getShipmentDetailFields = useCallback(
        () =>
            dataModel?.isDomesticQuote
                ? DOMESTIC_SHIPMENT_DETAIL_FIELDS
                : ALL_SHIPMENT_DETAIL_FIELDS,
        [dataModel]
    );

    if (!dataModel || !dataModel.shipmentDetails?.length) {
        return null;
    }

    return (
        <div className='table-info'>
            <h4>{`Товары по заказу #${id}`}</h4>
            <table className='table table-striped'>
                <thead>
                    <tr>
                        {getShipmentDetailFields().map((field) => (
                            <th key={field}>{FIELD_NAMES[field]}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dataModel.shipmentDetails.map((detail) => (
                        <tr key={detail.id}>
                            {getShipmentDetailFields().map((field) => (
                                <td key={field}>{getFieldValue(detail, field, dataModel)}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ShipmentDetailsInfoTable;
