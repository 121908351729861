import { ReactElement } from 'react';
import {
    AuditChange,
    AuditListModel,
    AuditOperationType,
    AuditOperationTypeNames
} from '../../../model/audit/auditListModel';
import fieldToLabelMapping, { findFieldByLatin } from '../tableFieldToLabelMapping';
import { ProxyModel } from '../../../model/proxyModel';
import util, {BOOLEAN_REGEXP, DATETIME_REGEXP, DATE_REGEXP, TIME_REGEXP} from '../../../common/util';

const auditUtil = {
    group: (auditList: AuditListModel[]): ProxyModel<AuditListModel>[] => {
        const groupedRecords = auditList.reduce(
            (grouped: { [key: string]: ProxyModel<AuditListModel> }, audit) => {
                const { transactionId, createdAt, createdBy } = audit;
                const groupKey = `${transactionId}-${util.formatDateTime(createdAt)}-${createdBy}`;

                if (!grouped[groupKey]) {
                    grouped[groupKey] = {
                        transactionId,
                        createdAt,
                        createdBy,
                        refs: [audit]
                    } as ProxyModel<AuditListModel>;
                } else {
                    grouped[groupKey].refs.push(audit);
                }

                return grouped;
            }, {});

        return Object.values(groupedRecords);
    },

    renderChanges: (mainSource: string, auditList: AuditListModel[]): ReactElement => {
        return (
            <div>
                {auditList
                    .sort((a, b) => {
                        const aIsSource = a.source === mainSource;
                        const bIsSource = b.source === mainSource;
                        if (aIsSource && !bIsSource) return -1;
                        if (bIsSource && !aIsSource) return 1;
                        return 0;
                    })
                    .map((audit, index) => (
                        <span key={index}>
                            {auditUtil.renderTableChanges(mainSource, audit)}
                        </span>
                    ))
                }
            </div>
        );
    },

    renderTableChanges: (mainSource: string, auditModel: AuditListModel): ReactElement => {
        const { source, sourceId, operation, changes } = auditModel;
        const tableData = fieldToLabelMapping[source];
        if (!tableData) {
            throw new Error(`Table '${source}' not found in dictionary`);
        }
        const { cyrillic, parentDependentCyrillic } = tableData.table;
        const tableLabel = mainSource && parentDependentCyrillic && parentDependentCyrillic[mainSource]
                ? parentDependentCyrillic[mainSource] : cyrillic;
        return (
            <div>
                <div className='font-weight-bold'>
                    {`${tableLabel} ${sourceId > 0 ? sourceId : ''} - ${AuditOperationTypeNames.get(operation)}`}
                </div>
                {changes
                    .filter((change) => change.field !== 'id')
                    .map((change, index) => {
                        const label = findFieldByLatin(tableData, change.field)?.cyrillic;
                        if (!label) return (<></>);
                        return (
                            <div key={index} className='pl-3'>
                                {auditUtil.formatFieldChange(label, operation, change)}
                            </div>
                        );
                    })}
            </div>
        );
    },

    formatFieldChange: (label: string, operation: AuditOperationType, change: AuditChange): string => {
        const oldValue = change.oldValuePlaceholder ?? auditUtil.formatValue(change.oldValue);
        const newValue = change.newValuePlaceholder ?? auditUtil.formatValue(change.newValue);

        switch (operation) {
            case AuditOperationType.INSERT:
                return `${label}: ${newValue}`;
            case AuditOperationType.DELETE:
                return `${label}: ${oldValue}`;
            case AuditOperationType.UPDATE:
                return `${label}: ${oldValue} -> ${newValue}`;
        }
    },

    formatValue: (value: any): string => {
        if (!value) {
            return '(нет значения)';
        }

        if (typeof value === 'string') {
            if (value.match(BOOLEAN_REGEXP)) {
                return value === 'true' ? 'да' : 'нет';
            }

            if (value.match(TIME_REGEXP)) {
                return util.formatTime(value);
            } else if (value.match(DATE_REGEXP)) {
                return util.formatDate(value);
            } else if (value.match(DATETIME_REGEXP)) {
                return util.formatDateTime(new Date(value));
            }
        }
        return String(value);
    },

};

export default auditUtil;
