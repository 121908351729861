import { DictionaryType } from '../control/option';

export enum ColumnType {
    ID,
    TEXT,
    INTEGER,
    DECIMAL,
    MONEY,
    BOOLEAN,
    DATE,
    TIME,
    DATE_TIME,
    CLIENT_ALL,
    CLIENT,
    CARRIER,
    SUPPLIER,
    COMPANY,
    ASSORTMENT,
    CLIENT_ASSORTMENT,
    LOGISTIC_MANAGER,
    COMMERCIAL_MANAGER,
    PAYMENT_TYPE,
    QUOTE_STATUS,
    NDS_MODE,
    DESCRIPTION,
    CLIENT_TYPE,
    CLIENT_KIND,
    CLIENT_STATUS,
    ADDRESS,
    CONTRACT_TYPE,
    CONTRACT_STATUS,
    RUN_STATUS,
    KPP_RUN_STATUS,
    RUN_TYPE,
    TTN_STATUS,
    VEHICLE_TYPE,
    VEHICLE_OWNERSHIP,
    APPROVAL_STATUS,
    CARCASS,
    POSITION,
    UNIT,
    EXPENSE_ITEM,
    OPERATION_TYPE,
    STORAGE,
    TMC,
    QUOTE_SOURCE,
    CLIENT_CONTACT,
    CLIENT_STORAGE,
    ATTACHMENT_KIND,
    STORAGE_STATUS,
    CLIENT_CONSIGNEE_ASSORTMENT,
    PAYMENT_METHOD,
    ROLE,
    TMC_CATEGORY,
    TMC_PACKAGE_CATEGORY,
    TMC_TARE_CATEGORY,
    PRODUCTION_LINE,
    DW_TEXT,
    RUN_LOADING_STATUS,
    EDI_PARTY,
    ASSORTMENT_TYPE,
    TMC_TYPE,
}

export const COLUMN_TO_OPTION_TYPE: Map<ColumnType, DictionaryType> = new Map([
    [ColumnType.QUOTE_STATUS, DictionaryType.QUOTE_STATUS],
    [ColumnType.QUOTE_SOURCE, DictionaryType.QUOTE_SOURCE],
    [ColumnType.PAYMENT_TYPE, DictionaryType.PAYMENT_TYPE],
    [ColumnType.PAYMENT_METHOD, DictionaryType.PAYMENT_METHOD],

    [ColumnType.CLIENT_TYPE, DictionaryType.CLIENT_TYPE],
    [ColumnType.CLIENT_KIND, DictionaryType.CLIENT_KIND],
    [ColumnType.CLIENT_STATUS, DictionaryType.CLIENT_STATUS],

    [ColumnType.CONTRACT_TYPE, DictionaryType.CONTRACT_TYPE],
    [ColumnType.CONTRACT_STATUS, DictionaryType.CONTRACT_STATUS],

    [ColumnType.RUN_STATUS, DictionaryType.RUN_STATUS],
    [ColumnType.RUN_TYPE, DictionaryType.RUN_TYPE],
    [ColumnType.TTN_STATUS, DictionaryType.TTN_STATUS],

    [ColumnType.UNIT, DictionaryType.UNIT],
    [ColumnType.OPERATION_TYPE, DictionaryType.OPERATION_TYPE],
    [ColumnType.APPROVAL_STATUS, DictionaryType.APPROVAL_STATUS],
    [ColumnType.STORAGE_STATUS, DictionaryType.STORAGE_STATUS],
    [ColumnType.ROLE, DictionaryType.ROLE],
    [ColumnType.TMC_CATEGORY, DictionaryType.TMC_CATEGORY],
    [ColumnType.EXPENSE_ITEM, DictionaryType.EXPENSE_ITEM],
    [ColumnType.ATTACHMENT_KIND, DictionaryType.ATTACHMENT_KIND],
    [ColumnType.ASSORTMENT_TYPE, DictionaryType.ASSORTMENT_TYPE],
    [ColumnType.TMC_TYPE, DictionaryType.ASSORTMENT_TYPE],
]);
