import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import runs from '../../../api/runs';
import { PATH_RUNS, PATH_VARIABLE_RUN_ID } from '../../../routerPaths';
import {
    AttachmentModel,
    AttachmentSubtype,
    AttachmentSubtypeNames,
    AttachmentType,
    ListAttachmentModel
} from '../../../model/attachment';
import { FormConfig } from '../../form/formConfig';
import { DwForm } from '../../form/DwForm';
import ContextError from '../../form/ContextError';
import AttachmentsTable from '../../form/attachments/AttachmentsTable';
import UploadArea from '../../form/attachments/UploadArea';
import util from '../../../common/util';
import { Option } from '../../control/option';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';

enum RunAttachmentsField {
    CLIENT_ATTACHMENTS = 'clientAttachments',
    CARRIER_ATTACHMENTS = 'carrierAttachments',
    INNER_ATTACHMENTS = 'innerAttachments',
}

type RunAttachmentListModel = {
    clientAttachments: AttachmentModel[];
    carrierAttachments: AttachmentModel[];
    innerAttachments: AttachmentModel[];
}

const load = (runId: number): Promise<RunAttachmentListModel> => {
    return new Promise((resolve) => {
        runs.getAttachments(runId)
            .then((model: ListAttachmentModel) => {
                const { attachments } = model;
                const clientAttachments: AttachmentModel[] = [];
                const carrierAttachments: AttachmentModel[] = [];
                const innerAttachments: AttachmentModel[] = [];

                attachments.forEach(attachment => {
                    switch (attachment.subtype?.value) {
                        case AttachmentSubtype.RUN_CLIENT:
                            clientAttachments.push(attachment);
                            break;
                        case AttachmentSubtype.RUN_CARRIER:
                            carrierAttachments.push(attachment);
                            break;
                        case AttachmentSubtype.RUN_INNER:
                            innerAttachments.push(attachment);
                            break;
                        default:
                            break;
                    }
                });

                resolve({ clientAttachments, carrierAttachments, innerAttachments });
            });
    });
}

export const useFormConfig = (runId: number) =>
    useMemo(() => {
            return FormConfig.builder<RunAttachmentListModel>()
                .list<AttachmentModel>(
                    RunAttachmentsField.CLIENT_ATTACHMENTS,
                    (m) => m.clientAttachments,
                    (m, v) => (m.clientAttachments = v),
                )
                .list<AttachmentModel>(
                    RunAttachmentsField.CARRIER_ATTACHMENTS,
                    (m) => m.carrierAttachments,
                    (m, v) => (m.carrierAttachments = v),
                )
                .list<AttachmentModel>(
                    RunAttachmentsField.INNER_ATTACHMENTS,
                    (m) => m.innerAttachments,
                    (m, v) => (m.innerAttachments = v),
                )
                .load(load)
                .submit((model) => {
                    const attachments = [
                        ...model.clientAttachments,
                        ...model.carrierAttachments,
                        ...model.innerAttachments
                    ];
                    return runs.saveAttachments(runId, { attachments })
                })
                .idPathVariableName(PATH_VARIABLE_RUN_ID)
                .redirectUrl(PATH_RUNS)
                .build();
        },
        [runId]
    );

const RunAttachmentsArea: React.FC = () => {
    const runId = util.toNumber(useParams()[PATH_VARIABLE_RUN_ID]!) ?? -1;
    const formConfig = useFormConfig(runId);
    return (
        <DwForm config={formConfig}>
            <RunAttachmentsAreaForm runId={runId} />
            <ContextError />
        </DwForm>
    );
};

type Props = {
    runId: number;
};

const RunAttachmentsAreaForm: React.FC<Props> = ({ runId }) => {
    return (
        <div className='run'>
            <RunAttachmentsAreaCard
                id={RunAttachmentsField.CLIENT_ATTACHMENTS}
                attachmentSubtype={AttachmentSubtype.RUN_CLIENT}
                runId={runId}
            />
            <RunAttachmentsAreaCard
                id={RunAttachmentsField.CARRIER_ATTACHMENTS}
                attachmentSubtype={AttachmentSubtype.RUN_CARRIER}
                runId={runId}
            />
            <RunAttachmentsAreaCard
                id={RunAttachmentsField.INNER_ATTACHMENTS}
                attachmentSubtype={AttachmentSubtype.RUN_INNER}
                runId={runId}
            />
            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </div>
    );
}

type CardProps = {
    id: string;
    runId: number;
    attachmentSubtype: AttachmentSubtype;
};

const RunAttachmentsAreaCard: React.FC<CardProps> = ({ id, runId, attachmentSubtype }) => {
    const props = { id, attachmentType: AttachmentType.RUN, parentId: runId };
    const subtype = {
        value: attachmentSubtype,
        label: AttachmentSubtypeNames.get(attachmentSubtype)
    } as Option;
    return (
        <div className='card h-100 mb-3'>
            <div className='card-header d-flex flex-row pt-2 pb-2'>
                <div className='mr-auto align-self-start'/>
                <div className='align-self-center'>{subtype.label}</div>
                <div className='ml-auto align-self-end'/>
            </div>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className={'form-group attachments-area'}>
                            <AttachmentsTable {...props} />
                            <UploadArea {...props} attachmentSubtype={subtype} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RunAttachmentsArea;
