export enum ClientAssortmentField {
    ASSORTMENT_ID = 'assortmentId',
    CLIENT_ID = 'clientId',
    CLIENT_NAME = 'clientName',
    NAME = 'name',
    CLIENT_LABEL = 'clientLabel',
    LABEL = 'label',
    USE_NET = 'useNet',
    USE_PASSPORT = 'usePassport',
    USE_TALMAN = 'useTalman',
    WEIGHT_FROM = 'weightFrom',
    WEIGHT_TO = 'weightTo',
    WEIGHT_MIN = 'weightMin',
    WEIGHT_AVG = 'weightAvg',
    BOXES = 'boxes',
    UNIT_BARCODE = 'unitBarcode',
    PACKAGE_BARCODE = 'packageBarcode',
    NUMBER = 'number',
    COMMENT = 'comment',
    PACKAGE_TMC = 'packageTmc',
    TARE_TMC = 'tareTmc',
    TEMPERATURE_REGIME = 'temperatureRegime',
    UNIT = 'unit',
}
