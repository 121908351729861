import React, { useContext } from 'react';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import { generatePath, useNavigate } from 'react-router-dom';
import './shipment-details.scss';
import { FormContext, useFieldValue } from '../../form/DwForm';
import { QuoteModel } from '../model/quoteModel';
import util from '../../../common/util';
import RunsOptions from '../RunsOptions';
import { Option } from '../../control/option';
import { PATH_RUN } from '../../../routerPaths';
import ShipmentDetailsTable from './ShipmentDetailsTable';
import { QuoteField } from '../quoteField';
import { RemoveButton } from '../../table/RemoveButton';
import runs from '../../../api/runs';
import { Context } from '../../form/context';

type Props = {
    id: string;
};

const ShipmentDetails: React.FC<Props> = ({id}) => {
    const context = useContext(FormContext);
    const navigate = useNavigate();
    const {state: {model}, setFieldValues} = context as Context<QuoteModel>;
    const valueId = useFieldValue(QuoteField.ID);
    const valueRun = useFieldValue(QuoteField.RUN);
    const valueClient = useFieldValue(QuoteField.CLIENT);
    const valueLoadingAddress = useFieldValue(QuoteField.LOADING_ADDRESS);
    const valueLoadingDate = useFieldValue(QuoteField.LOADING_DATE);

    const createRun = (e: React.UIEvent<HTMLElement> | React.KeyboardEvent): void => {
        e.preventDefault();
        redirectToRun({} as Option, getQuoteState());
    };

    const addRun = (run: SingleValue<Option>) => {
        redirectToRun(run as Option, getQuoteState());
    };

    const unlinkFromRun = (e: React.MouseEvent) => {
        e.preventDefault();
        runs.unlinkQuote(valueId).then(() => {
            setFieldValues((m) => {
                m.run = {} as Option
            });
        });
    };

    const getQuoteState = () => ({
        state: {
            quoteOption: {value: valueId, label: ''},
        },
    });

    const redirectToRun = (run: Option, state: any) =>
        navigate(generatePath(PATH_RUN, {runId: util.stringOrEmpty(run?.value)}), state);

    const renderRunsOptions = () => (
        <RunsOptions
            isCarrierThirdParty={model.carrierThirdParty ?? false}
            clientId={valueClient?.value}
            loadingDate={valueLoadingDate}
            loadingAddress={valueLoadingAddress}
            onChange={addRun}
            isDomesticQuote={model.isDomesticQuote ?? false}
        />
    );

    const renderCreateRunBtn = () => (
        <button className='btn btn-primary btn-sm' onClick={createRun}>
            создать рейс
        </button>
    );

    const renderRunInfo = () => (
        <div className='d-flex align-items-center'>
            <strong>Рейс по заказу:</strong>
            <button onClick={() => redirectToRun(valueRun, {})} className='btn btn-sm btn-link'>
                {valueRun.label ? valueRun.label : valueRun.value}
            </button>
            <RemoveButton onClick={unlinkFromRun} tooltip='отвязать рейс от заказа' />
        </div>
    );

    const renderRun = () => {
        if (valueId) {
            return valueRun?.value ? (
                renderRunInfo()
            ) : (
                <>
                    {renderRunsOptions()}
                    &nbsp;
                    {renderCreateRunBtn()}
                </>
            );
        }
    };

    return (
        <div className='card d-flex'>
            <div className='card-body'>
                <div className='row mb-3'>
                    <div className='col-lg-3 col-md-3'>
                        <h5>
                            <strong>Грузы и рейсы</strong>
                        </h5>
                    </div>
                    <div className='col-lg-9 col-md-9'>
                        <div className='d-flex flex-row justify-content-end align-items-center run-info'>
                            {renderRun()}
                        </div>
                    </div>
                </div>
                <ShipmentDetailsTable id={id}/>
            </div>
        </div>
    );
};

export default ShipmentDetails;
