export type AuditListModel = {
    transactionId: number;
    createdAt: Date;
    createdBy: String;
    source: string;
    sourceId: number;
    operation: AuditOperationType;
    changes: AuditChange[];
};

export type AuditChange = {
    field: string;
    oldValue: any;
    oldValuePlaceholder: string;
    newValue: any;
    newValuePlaceholder: string;
};

export enum AuditOperationType {
    INSERT = 'I',
    UPDATE = 'U',
    DELETE = 'D',
};

export const AuditOperationTypeNames: Map<AuditOperationType, string> = new Map([
    [AuditOperationType.INSERT, 'Добавление записи'],
    [AuditOperationType.UPDATE, 'Обновление записи'],
    [AuditOperationType.DELETE, 'Удаление записи'],
]);