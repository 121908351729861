export enum RouteField {
    IS_LOADING = 'isLoading',
    QUOTE_ID = 'quoteId',
    DATE = 'date',
    TIME_FROM = 'timeFrom',
    DURATION = 'duration',
    CLIENT = 'client',
    COMPANY = 'company',
    ADDRESS = 'address',
    STORAGE = 'storage',
    CONTACT = 'contact',
    CONTACT_PHONE = 'contactPhone',
    WEIGHT = 'weight',
    TARE_QUANTITY = 'tareQuantity',
    DESCRIPTION = 'description',
    CARGO_PRICE = 'cargoPrice',
}