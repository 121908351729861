import Holidays from 'date-holidays';
import { DelayType } from '../model/enums/DelayType';
import type { Option  } from '../component/control/option';
import { DEFAULT_OPTION } from '../component/control/option';

export const BOOLEAN_REGEXP = /^(true|false)$/;
export const TIME_REGEXP = /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9](\.\d{1,3})?$/;
export const DATE_REGEXP = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
export const DATETIME_REGEXP = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])[ T](0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9](\.\d{1,6})?$/;

type DatePart = 'years' | 'months' | 'days' | 'hours' | 'minutes';

const util = {
    pad: (num: number, size: number): string => {
        let str = num.toString();
        while (str.length < size) str = '0' + num;
        return str;
    },
    toNumber: (val: string): number | undefined => (val ? Number(val) : undefined),
    buildPath: (...args: string[]): string => args.join('/').replaceAll('//', '/'),

    formatDate: (input: Date | string): string => {
        if (!input) {
            return '';
        }
        const date = typeof input === 'string' ? new Date(input) : input;
        return date.toLocaleDateString('ru-RU');
    },
    formatTime: (input: Date | string): string => {
        if (!input) {
            return '';
        }
        if (typeof input === 'string' && input.match(TIME_REGEXP)) {
            const [hours, minutes] = input.split(':');
            return `${hours}:${minutes}`;
        }
        input = new Date(input);
        return util.pad(input.getHours(), 2) + ':' + util.pad(input.getMinutes(), 2);
    },
    formatDateTime: (input: Date | string): string => {
        const date = typeof input === 'string' ? new Date(input) : input;
        return date
            ? date.toLocaleDateString('ru-RU') +
            ' ' +
            util.pad(date.getHours(), 2) +
            ':' +
            util.pad(date.getMinutes(), 2)
            : '';
    },

    toISODateTimeWithoutTimezone: (date: Date | string): string => {
        const isoDate = new Date(date);
        return new Date(isoDate.getTime() - isoDate.getTimezoneOffset() * 60 * 1000)
            .toISOString()
            .slice(0, -1); // Remove the last 'Z' indicating local time
    },

    isValidISODateTime: (date: Date | string): boolean => {
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
        const isoString = date instanceof Date ? date.toISOString() : date;
        return regex.test(isoString);
    },

    toLocalDate: (date: Date): Date => {
        const local = new Date();
        local.setFullYear(date.getFullYear());
        local.setMonth(date.getMonth());
        local.setDate(date.getDate());
        local.setHours(date.getHours());
        local.setMinutes(date.getMinutes());
        return new Date(local.toISOString().slice(0, -1));
    },

    timeToDate: (value: any): Date => {
        if (value && typeof value === 'string') {
            return value.match(TIME_REGEXP) ? new Date(`1970-1-1 ${value}`) : new Date(value);
        }
        return value;
    },

    toDateForDtPicker: (value: any, showTimeSelectOnly: boolean): Date => {
        if (showTimeSelectOnly) {
            return util.timeToDate(value);
        }
        return typeof value === 'string' ? new Date(value) : value;
    },

    getDateFormats: (showTimeSelect: boolean, showTimeSelectOnly: boolean): string[] => {
        if (showTimeSelectOnly) {
            return ['HH:mm'];
        }
        const baseFormat = showTimeSelect ? 'd MMMM, yyyy HH:mm' : 'd MMMM, yyyy';
        return [baseFormat, 'dd.MM.yyyy'];
    },

    isObject: (item: any) => {
        return item && typeof item === 'object' && !Array.isArray(item);
    },

    deepMerge: (target: any, ...sources: any): any => {
        if (!sources.length) return target;
        const source = sources.shift();

        if (util.isObject(target) && util.isObject(source)) {
            for (const key in source) {
                if (util.isObject(source[key])) {
                    if (!target[key]) Object.assign(target, {[key]: {}});
                    util.deepMerge(target[key], source[key]);
                } else {
                    Object.assign(target, {[key]: source[key]});
                }
            }
        }
        return util.deepMerge(target, ...sources);
    },

    toString: (value: any): string | null => {
        if (value === null || value === undefined) {
            return '';
        }
        switch (typeof value) {
            case 'boolean':
                return value ? 'да' : 'нет';
            case 'object':
                if ('value' in value && 'label' in value) {
                    return (value as Option).label;
                }
        }
        return value as string;
    },

    stringOrEmpty: (value: any): string | null => {
        const stringValue = util.toString(value);
        if (value === null || value === undefined) {
            return '';
        }
        return stringValue;
    },

    sumOverObjectProps: <T>(data: T[], property: keyof T, digits?: number): number => {
        const sum = data?.reduce((total, obj) => total + Number(obj[property] ?? 0), 0) ?? 0;
        return digits && digits > 0 ? parseFloat(sum.toFixed(digits)) : sum;
    },

    countTotal: <T>(data: T[], getter: (item: T) => number) =>
        data.map(getter).reduce((acc, value) => acc + value, 0),

    addBusinessDays: (date: Date, days: number): Date => {
        const dt = new Date(date);
        const hd = new Holidays('RU');
        while (days > 0) {
            dt.setDate(dt.getDate() + 1);
            if (dt.getDay() !== 0 && dt.getDay() !== 6 && !hd.isHoliday(dt)) {
                days--;
            }
        }
        return dt;
    },

    addDays: (date: Date, days: number): Date => {
        const dt = new Date(date);
        dt.setDate(dt.getDate() + (days ?? 0));
        return dt;
    },

    calculatePlannedPaymentDate: (
        date: Date,
        paymentDelayDays: number,
        delayType: DelayType
    ): Date => {
        switch (delayType) {
            case DelayType.CALENDAR:
                return util.addDays(date, paymentDelayDays);
            case DelayType.BUSINESS:
                return util.addBusinessDays(date, paymentDelayDays);
            default:
                throw new Error('Invalid delay type');
        }
    },

    incrementDate: (date: Date, part: DatePart, amount: number): Date => {
        const asDate: Date = new Date(date);
        switch (part) {
            case 'years':
                asDate.setFullYear(asDate.getFullYear() + amount);
                break;
            case 'months':
                asDate.setMonth(asDate.getMonth() + amount);
                break;
            case 'days':
                asDate.setDate(asDate.getDate() + amount);
                break;
            case 'hours':
                asDate.setHours(asDate.getHours() + amount);
                break;
            case 'minutes':
                asDate.setMinutes(asDate.getMinutes() + amount);
                break;
            default:
                throw new Error('Invalid date part');
        }

        return asDate;
    },

    getEnumOptions: (allValues: string[]): Option[] => {
        return allValues.map((item, index) => ({value: index + 1, label: item}));
    },

    getEnumOptionByValue: (allValues: string[], value: string): Option => {
        return util.getEnumOptions(allValues)
            .find(option => option.label === value) ?? DEFAULT_OPTION;
    },

    getOptionOrDefault: (option: Option): Option => {
        return option?.value > 0 ? option : DEFAULT_OPTION;
    },

    findLastIndex: <T>(arr: T[], predicate: (value: T, index: number) => boolean): number => {
        const lastIndex = [...arr].reverse().findIndex(predicate);
        return lastIndex !== -1 ? arr.length - lastIndex - 1 : -1;
    },

    dateTimeSort: (aDate: Date, aTime: Date, bDate: Date, bTime: Date): number => {
        if (!aDate && !bDate) return 0;
        if (!aDate) return 1;
        if (!bDate) return -1;
        const aDateAsDate = new Date(aDate).setHours(0, 0, 0, 0),
            bDateAsDate = new Date(bDate).setHours(0, 0, 0, 0);
        if (aDateAsDate !== bDateAsDate) {
            return aDateAsDate - bDateAsDate;
        }

        if (!aTime && !bTime) return 0;
        if (!aTime) return 1;
        if (!bTime) return -1;
        const aTimeAsDate = new Date(aTime).setFullYear(1970, 0, 1),
            bTimeAsDate = new Date(bTime).setFullYear(1970, 0, 1);
        return aTimeAsDate - bTimeAsDate;
    },

    numberSort: (a: number, b: number): number => a - b,

    calcPercent: (progress: number, total: number): number => {
        return progress > 0 && total > 0 ? Math.round(((progress) / total) * 100) : 0;
    },

    calcSum: (...numbers: number[]): number => {
        const sum = numbers.reduce((total, num) => total + Number(num ?? 0), 0);
        return parseFloat(Number(sum).toFixed(2));
    },

    cropName: (text: string, withDot?: boolean): string => {
        if (!text || text.length === 0) {
            return '';
        }

        const firstChar = text.trim().charAt(0).toUpperCase();
        return withDot ? `${firstChar}.` : firstChar;
    },

    capitalizeFirstChar: (text: string): string => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    },
};
export default util;
