import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { PATH_QUOTES, PATH_KPP } from '../../routerPaths';
import { RootContext } from './ContextWrapper';
import { Role } from '../../model/role';

const IndexNavigation: React.FC = () => {
    const context = useContext(RootContext);
    const { state: { profile } } = context;

    const renderNavigation = () => {
        const isKpp = profile.roles.includes(Role.KPP);
        if (isKpp) {
            return <Navigate to={PATH_KPP} />;
        }
        return <Navigate to={`${PATH_QUOTES}?domestic=false`} />;
    };

    return profile.id > 0
        ? renderNavigation()
        : <></>;
};

export default IndexNavigation;
