import React from 'react';
import { PagedResponse } from '../../../api/pagedResponse';
import storages from '../../../api/storages';
import { ColumnType } from '../../table/columnType';
import { Config } from '../../table/config';
import { DwTable } from '../../table/DwTable';
import { StorageStateChartField } from './storageStateChartField';
import { StorageStateChartModel } from './storageStateChartModel';

const config = Config.builder<StorageStateChartModel>()
    .column((builder) => builder.text('Номенклатура', StorageStateChartField.ASSORTMENT_NAME))
    .column((builder) => builder.text('Итого склад', StorageStateChartField.STORAGE_TOTAL))
    .column((builder) => builder.text('В пути', StorageStateChartField.WEIGHT_ON_WAY))
    .column((builder) =>
        builder.text('Свободно в бинах', StorageStateChartField.WEIGHT_STORAGE_AVAILABLE)
    )
    .column((builder) =>
        builder.text('Резерв в бинах', StorageStateChartField.WEIGHT_STORAGE_RESERVED)
    )
    .column((builder) =>
        builder.text('Собрано в паллетах', StorageStateChartField.WEIGHT_PRODUCTION)
    )
    .column((builder) => builder.text('Готово к отгрузке', StorageStateChartField.WEIGHT_TO_LOAD))
    .column((builder) => builder.text('Отгружено сегодня', StorageStateChartField.WEIGHT_LOADED))
    .load((ctx) =>
        storages.getStorageStateChart(ctx.state.request).then((it) => {
            return { totalRecords: -1, data: it } as PagedResponse<StorageStateChartModel>;
        })
    )
    .outerFilter((builder) => builder.field('storage').type(ColumnType.STORAGE))
    .id('storage-state-chart')
    .withAdd(false)
    .withSearch(false)
    .editable(false)
    .sortable(false)
    .filterable(false)
    .columnsSelector(false)
    .onClick(() => null)
    .build();

const StorageStateChart: React.FC = () => {
    return (
        <div className='storage-state-chart d-flex'>
            <DwTable
                header={`Состояние склада на ${new Date().toLocaleDateString('RU-ru')}`}
                config={config}
            />
        </div>
    );
};

export default StorageStateChart;
