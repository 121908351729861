import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, generatePath, useParams, useSearchParams } from 'react-router-dom';
import DwFormTabs from '../../form/DwFormTabs';
import { ClientModel } from '../../../model/client/clientModel';
import clients from '../../../api/clients';
import {
    PATH_VARIABLE_CLIENT_ID,
    PATH_CP_CLIENT,
    PATH_CP_CLIENT_CONTRACTS,
    PATH_CP_CLIENT_CONTACTS,
    PATH_CP_CLIENT_HISTORY,
    PATH_CP_CLIENT_ADDRESSES,
    PATH_CP_CLIENT_ACCOUNTS,
    PATH_CP_CLIENT_ASSORTMENT,
    PATH_CP_CLIENT_CONSIGNEES,
    SEARCH_PARAM_CLIENT_EDI_MESSAGE_ID,
    SEARCH_PARAM_CONSIGNEE_EDI_MESSAGE_ID,
} from '../../../routerPaths';
import util from '../../../common/util';
import FormSkeleton from '../../form/FormSkeleton';
import edi from '../../../api/edi';
import { useFormVersion } from '../../form/DwForm';
import { TableRefreshWrapper } from '../../table/TableRefreshWrapper';

type SharedClientContext = {
    client: ClientModel;
};

export const ClientFormContext = React.createContext<SharedClientContext>(
    {} as SharedClientContext
);

const ClientForm: React.FC = () => {
    const [client, setClient] = useState<ClientModel>({} as ClientModel);
    const clientId = util.toNumber(useParams()[PATH_VARIABLE_CLIENT_ID]!)!;
    const [searchParams] = useSearchParams();
    const clientEdiMessageId = util.toNumber(searchParams.get(SEARCH_PARAM_CLIENT_EDI_MESSAGE_ID) || '') ?? 0;
    const consigneeEdiMessageId = util.toNumber(searchParams.get(SEARCH_PARAM_CONSIGNEE_EDI_MESSAGE_ID) || '') ?? 0;
    const { formVersion } = useFormVersion();

    useEffect(() => {
        let shouldUpdate = true;
        (clientId > 0 ? clients.get(clientId) : (
            clientEdiMessageId > 0 ? edi.getClientFromEdi(clientEdiMessageId) : (
                consigneeEdiMessageId > 0 ? edi.getConsigneeFromEdi(consigneeEdiMessageId) : Promise.resolve({} as ClientModel)
            ))).then((client) => shouldUpdate && setClient(client));

        return () => { shouldUpdate = false; }
    }, [clientId, clientEdiMessageId, consigneeEdiMessageId, formVersion]);

    const clientFormContextValue = useMemo(() => ({ client }), [client]);

    const isNewClient = !client?.id || client.id <= 0;
    const clientIdPath = util.stringOrEmpty(clientId);
    if (clientId > 0 && !client.id) {
        return (
            <div className='form-wrapper d-flex flex-column flex-sm-fill'>
                <div>
                    <FormSkeleton className={'col-1 mr-2'} />
                    <FormSkeleton className={'col-1 mr-2'} />
                    <FormSkeleton className={'col-1 mr-2'} />
                    <FormSkeleton className={'col-1 mr-2'} />
                    <FormSkeleton className={'col-1'} />
                </div>
                <div className='d-flex h-100 mt-2'>
                    <FormSkeleton className={'card-body'} />
                </div>
            </div>
        );
    }
    return (
        <ClientFormContext.Provider value={clientFormContextValue}>
            <TableRefreshWrapper>
                <div className='form-wrapper'>
                    <DwFormTabs
                        items={[
                            {
                                name: isNewClient ? 'Новый контрагент' : client?.name,
                                path: generatePath(PATH_CP_CLIENT, { [PATH_VARIABLE_CLIENT_ID]: clientIdPath }),
                                end: true,
                            },
                            {
                                name: `Р/Счета`,
                                path: generatePath(PATH_CP_CLIENT_ACCOUNTS, { [PATH_VARIABLE_CLIENT_ID]: clientIdPath }),
                                disabled: isNewClient,
                            },
                            {
                                name: `Договоры`,
                                path: generatePath(PATH_CP_CLIENT_CONTRACTS, { [PATH_VARIABLE_CLIENT_ID]: clientIdPath }),
                                disabled: isNewClient,
                            },
                            {
                                name: `Контакты`,
                                path: generatePath(PATH_CP_CLIENT_CONTACTS, { [PATH_VARIABLE_CLIENT_ID]: clientIdPath }),
                                disabled: isNewClient,
                            },
                            {
                                name: `Адреса`,
                                path: generatePath(PATH_CP_CLIENT_ADDRESSES, { [PATH_VARIABLE_CLIENT_ID]: clientIdPath }),
                                disabled: isNewClient,
                            },
                            {
                                name: `Чек-лист`,
                                path: generatePath(PATH_CP_CLIENT_ASSORTMENT, { [PATH_VARIABLE_CLIENT_ID]: clientIdPath }),
                                disabled: isNewClient,
                            },
                            {
                                name: 'Грузополучатели',
                                path: generatePath(PATH_CP_CLIENT_CONSIGNEES, { [PATH_VARIABLE_CLIENT_ID]: clientIdPath }),
                                disabled: isNewClient,
                            },
                            {
                                name: 'История',
                                path: generatePath(PATH_CP_CLIENT_HISTORY, { [PATH_VARIABLE_CLIENT_ID]: clientIdPath }),
                                disabled: isNewClient,
                                end: true,
                            },
                        ]}
                    />
                    <Outlet />
                </div>
            </TableRefreshWrapper>
        </ClientFormContext.Provider>
    );
};

export default ClientForm;
