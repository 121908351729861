import React, {MouseEvent, useContext, useEffect, useMemo, useState} from 'react';
import {Modal, ModalBtn} from '../../layout/Modal';
import {FormContext, useSetFieldValue, validateField} from '../../form/DwForm';
import util from '../../../common/util';
import {RunModel} from '../model/runModel';
import {RunExpensesModel} from '../model/runExpensesModel';
import {Config} from '../../table/config';
import {EditableDwTable} from '../../table/EditableDwTable';
import {RemoveButton} from '../../table/RemoveButton';
import FormInvalidText from '../../form/FormInvalidText';
import {ColumnType} from '../../table/columnType';
import {useIsMounted} from '../../../common/isMounted';
import {RunExpenseField} from './runExpenseField';

type Props = {
    id: string;
};

const useFormConfig = (
    id: string,
    onAdd: (e: React.UIEvent<HTMLElement> | React.KeyboardEvent) => void,
    onDelete: (e: MouseEvent, row: RunExpensesModel, index: number) => void,
) =>
    useMemo(
        () =>
            Config.builder<RunExpensesModel>()
                .column((builder) => builder.position('#').cls('id'))
                .column((builder) =>
                    builder
                        .label('Статья расходов')
                        .field(RunExpenseField.ITEM)
                        .type(ColumnType.EXPENSE_ITEM)
                        .editable()
                        .cls('type')
                )
                .column((builder) => builder.money('Сумма', RunExpenseField.AMOUNT).editable().cls('money'))
                .column((builder) =>
                    builder.text('Комментарий', RunExpenseField.COMMENT).editable().cls('text')
                )
                .column((builder) =>
                    builder
                        .getter((model, index) => (
                            <RemoveButton onClick={(e: MouseEvent) => onDelete(e, model, index!)}/>
                        ))
                        .cls('text-center actions')
                        .width('20px')
                )
                .id(id)
                .captionControl(() => (
                    <button type='button' className='btn btn-outline-primary btn-sm' onClick={onAdd}>
                        добавить
                    </button>
                ))
                .build(),
        [id, onAdd, onDelete]
    );

const calcExpenses = (expenses: RunExpensesModel[]) =>
    util.sumOverObjectProps(expenses, RunExpenseField.AMOUNT).toString();

const Expenses: React.FC<Props> = ({id}) => {
    const context = useContext(FormContext);
    const model = context.state.model as RunModel;
    const setValue = useSetFieldValue();
    const fieldConfig = context.state.config.field(id);
    const error = context.state.errors.get(id);
    const expensesDataValue = fieldConfig.getter(model);
    const expensesData = useMemo(() => expensesDataValue ?? [], [expensesDataValue]);
    const [modalShow, setModalShow] = useState(false);

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted && modalShow) {
            validateField(context, fieldConfig, model).then();
        }
    }, [expensesData]);

    const onChange = (details: RunExpensesModel[]) => setValue(id, details);

    const onDelete = async (e: MouseEvent, row: RunExpensesModel, index: number) => {
        e.preventDefault();
        const expenses = [...expensesData];
        expenses.splice(index, 1);
        onChange(expenses);
    };

    const onAdd = (e: React.UIEvent<HTMLElement> | React.KeyboardEvent): void => {
        e.preventDefault();
        const expenses = [...expensesData];
        expenses.push({} as RunExpensesModel);
        onChange(expenses);
    };

    const config = useFormConfig(id, onAdd, onDelete);

    const onCloseModal = (e: React.MouseEvent) => {
        e.preventDefault();
        setModalShow(false);
    };

    return (
        <div className='expenses'>
            <ModalBtn text={calcExpenses(expensesData)} setShow={(val) => setModalShow(val)}/>
            <FormInvalidText error={error}/>
            <Modal show={modalShow} onClose={onCloseModal}>
                <EditableDwTable config={config} header='Доп. расходы'/>
            </Modal>
        </div>
    );
};

export default Expenses;
