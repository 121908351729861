import React from 'react';
import { generatePath } from 'react-router-dom';
import vehicles from '../../../api/vehicles';
import { PATH_CP_VEHICLE } from '../../../routerPaths';
import { ColumnType } from '../../table/columnType';
import { Config } from '../../table/config';
import { DwTable, onRowDelete } from '../../table/DwTable';
import { RemoveButton } from '../../table/RemoveButton';
import { VehicleField } from './vehicleField';

import type { VehicleListModel } from '../../../model/vehicle/vehicleListModel';

const config = Config.builder<VehicleListModel>()
        .column((builder) => builder.id())
        .column((builder) => builder.text('Модель ТС', VehicleField.MODEL).sortable(false))
        .column((builder) => builder.text('Номер ТС', VehicleField.NUMBER).sortable(false))
        .column((builder) =>
            builder
                .label('Контрагенты')
                .field(VehicleField.CLIENTS)
                .type(ColumnType.CARRIER)
                .sortable(false)
                .cls('client')
        )
        .column((builder) =>
            builder
                .label('Тип ТС')
                .field(VehicleField.TYPE)
                .type(ColumnType.VEHICLE_TYPE)
                .cls('type')
        )
        .column((builder) =>
            builder
                .label('Тип собственности')
                .field(VehicleField.OWNERSHIP)
                .type(ColumnType.VEHICLE_OWNERSHIP)
                .sortable(false)
                .filterable(false)
                .cls('ownership')
        )
        .column((builder) =>
            builder
                .label('Тип кузова')
                .field(VehicleField.CARCASS)
                .type(ColumnType.CARCASS)
                .cls('carcass')
        )
        .column((builder) =>
            builder
                .label('Согласование')
                .field(VehicleField.APPROVAL)
                .type(ColumnType.APPROVAL_STATUS)
                .cls('approval')
        )
        .column((builder) =>
            builder.text('VIN номер', VehicleField.VIN).sortable(false).filterable(false)
        )
        .column((builder) =>
            builder.boolean('Не РФ', VehicleField.ALIEN).sortable(false).cls('boolean')
        )
        .column((builder) => builder.text('Кубатура', VehicleField.VOLUME).sortable(false))
        .column((builder) => builder.text('Тоннаж', VehicleField.TONNAGE).sortable(false))
        .column((builder) =>
            builder
                .text('Серия номер ПТС', VehicleField.PTS_INFO)
                .sortable(false)
                .filterable(false)
        )
        .column((builder) =>
            builder
                .date('Дата выдачи ПТС', VehicleField.PTS_DATE)
                .sortable(false)
                .filterable(false)
        )
        .column((builder) =>
            builder.text('ФИО Собственника', VehicleField.OWNER).sortable(false)
        )
        .column((builder) =>
            builder.text('Заметки', VehicleField.COMMENT).sortable(false).filterable(false)
        )
        .column((builder) =>
            builder
                .cls('text-center')
                .sortable(false)
                .filterable(false)
                .getter((model, _, refresh) => (
                    <RemoveButton
                        onClick={() => onRowDelete(refresh, () => vehicles.delete(model.id))}
                    />
                ))
                .width('45px')
        )
        .formUrl(generatePath(PATH_CP_VEHICLE, { vehicleId: '' }))
        .load((ctx) => vehicles.list(ctx.state.request))
        .id('vehicles')
        .defaultHidden([
            VehicleField.CLIENTS,
            VehicleField.OWNERSHIP,
            VehicleField.VIN,
            VehicleField.ALIEN,
            VehicleField.PTS_INFO,
            VehicleField.PTS_DATE,
            VehicleField.OWNER,
            VehicleField.COMMENT,
        ])
        .build();

const VehicleList: React.FC = () => {
    return (
        <div className='vehicles d-flex'>
            <DwTable header='Транспорт' config={config} />
        </div>
    );
};

export default VehicleList;
