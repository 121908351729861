import React from 'react';
import { useParams } from 'react-router-dom';
import { Config } from '../../table/config';
import { AuditListModel } from '../../../model/audit/auditListModel';
import { DwTable } from '../../table/DwTable';
import { AuditField } from './auditField';
import audit from '../../../api/audit';
import util from '../../../common/util';
import auditUtil from './auditUtil';
import { ProxyModel } from '../../../model/proxyModel';

type Props = {
    source: string;
    sourceIdPathVariableName: string;
};

const AuditList: React.FC<Props> = ({ source, sourceIdPathVariableName }) => {
    const sourceId = util.toNumber(useParams()[sourceIdPathVariableName]!) ?? -1;

    const config = Config.builder<ProxyModel<AuditListModel>>()
        .column((builder) =>
            builder
                .dateTime('Дата и время', AuditField.CREATED_AT)
                .sortable(false)
        )
        .column((builder) =>
            builder
                .text('Пользователь', AuditField.CREATED_BY)
                .sortable(false)
                .cls('user')
        )
        .column((builder) =>
            builder
                .text('Изменения', AuditField.CHANGES)
                .getter((model) => auditUtil.renderChanges(source, model.refs))
                .sortable(false)
                .filterable(false)
        )
        .load((ctx) => audit.list({ source, sourceId, listRequest: ctx.state.request })
                .then(response => ({
                        data: auditUtil.group(response.data),
                        totalRecords: response.totalRecords
                    }
                ))
        )
        .id('audit')
        .columnsSelector(false)
        .withSearch(false)
        .withAdd(false)
        .onClick(() => null)
        .build();

    return (
        <div className='audit d-flex table-no-hover'>
            <DwTable header='История изменений' config={config} />
        </div>
    );
};

export default AuditList;