import { BaseOptionRequest } from '../api/baseOptionRequest';
import { Option } from '../component/control/option';
import { AbstractEntity } from './abstractEntity';

export type ClientAssortmentRequest = BaseOptionRequest & {
    clientId: number;
    consigneeId?: number;
};

export type ClientAssortmentListModel = {
    id: number;
    number: string;
    unitBarcode: string;
    packageBarcode: string;
    name: string;
    clientName: string;
    clientLabelImage: string;
    labelImage: string;
    useNet: boolean;
    usePassport: boolean;
    useTalman: boolean;
    comment: string;
    boxes: number;
    weightFrom: number;
    weightTo: number;
    weightMin: number;
    weightAvg: number;
    packageTmc: string;
    tareTmc: string;
    temperatureRegime: string;
    unit: string;
};

export type ClientAssortmentModel = AbstractEntity & {
    assortmentId: number;
    clientId: number;
    number: string;
    unitBarcode: string;
    packageBarcode: string;
    clientName: string;
    name: Option;
    useNet: boolean;
    usePassport: boolean;
    useTalman: boolean;
    comment: string;
    boxes: number;
    weightFrom: number;
    weightTo: number;
    weightMin: number;
    weightAvg: number;
    packageTmc: Option;
    tareTmc: Option;
    temperatureRegime: string;
    unit: Option;
};

export type AssortmentModel = AbstractEntity & {
    value: string;
    grade: Option;
    unit: Option;
    quantity: number;
    active: boolean;
    ndsMode: Option;
    type: Option;
};

export type AssortmentListModel = AbstractEntity & {
    value: string;
    grade: string;
    unit: string;
    quantity: number;
    active: boolean;
    type: string;
};

export const TYPE_OPTIONS_FOR_ASSORTMENT = ['Продукция', 'Товары'] as const;
