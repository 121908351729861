import React from 'react';
import { Config } from '../../table/config';
import { DwTable, onRowDelete } from '../../table/DwTable';
import { RemoveButton } from '../../table/RemoveButton';
import { StorageField } from './storageField';
import storages from '../../../api/storages';
import { PATH_CP_STORAGE } from '../../../routerPaths';
import { generatePath } from 'react-router-dom';
import { ClientAddressListModel } from '../../../model/client/address';

const config = Config.builder<ClientAddressListModel>()
                .column((builder) => builder.id().sortable(false).filterable(false))
                .column((builder) => builder.text('Название', StorageField.NAME))
                .column((builder) =>
                    builder
                        .label('Город')
                        .getter((model) => model.city)
                        .sortable(false)
                        .filterable(false)
                )
                .column((builder) =>
                    builder
                        .label('Адрес')
                        .getter((model) => model.street)
                        .sortable(false)
                        .filterable(false)
                )
                .column((builder) =>
                    builder.text('Тип', 'type')
                        .sortable(false)
                        .filterable(false)
                )
                .column((builder) =>
                    builder
                        .cls('text-center')
                        .sortable(false)
                        .filterable(false)
                        .getter((model, _, refresh) => (
                            <RemoveButton
                                onClick={() =>
                                    onRowDelete(refresh, () => storages.delete(model.id))
                                }
                            />
                        ))
                        .width('45px')
                )
                .columnsSelector(false)
                .load((ctx) => storages.list(ctx.state.request))
                .formUrl(generatePath(PATH_CP_STORAGE, { storageId: '' }))
                .id('storages')
                .build();

export const StorageList: React.FC = () => {
    return (
        <div className='d-flex'>
            <DwTable header='Склады' config={config} />
        </div>
    );
};