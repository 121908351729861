import { PROMISE_OK } from '../const';
import { individualInnValidator, passportValidator, phoneValidator } from './commonValidators';
import type { CrmUserModel } from '../../model/crmUser/crmUserModel';
import { emailValidator, requiredValidator } from './simpleValidators';
import users from '../../api/users';
import { Role } from '../../model/role';

const password8AlphaNumericRegex = RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/);
const snils11NumericRegex = RegExp(/^\d{11}$/);
const isPassportAlien = (model: CrmUserModel) => model?.passportAlien || false;

export const crmUserPassportNumberValidator = async (model: CrmUserModel, required?: boolean) => {
    if (!model.passportNumber && required) {
        return requiredValidator(model);
    }
    if (!isPassportAlien(model)) {
        return passportValidator(model, required);
    }
    return Promise.resolve(PROMISE_OK);
};

export const crmUserPasswordValidator = (model: CrmUserModel) => {
    const { password } = model;
    const isValid = password8AlphaNumericRegex.test(password);

    if (password?.length > 0 && !isValid) {
        return Promise.resolve('Пароль должен содержать буквы и цифры (минимум 8 символов)');
    }

    return Promise.resolve(PROMISE_OK);
};

export const crmUserPhoneValidator = async (model: CrmUserModel) => {
    if (!model?.email && !model?.phone) {
        return Promise.resolve('Должен быть указан Email или Телефон');
    }

    if (model?.phone) {
        const phoneValidationRes = await phoneValidator(model, true, isPassportAlien(model));
        if (phoneValidationRes !== PROMISE_OK) {
            return Promise.resolve(phoneValidationRes);
        }

        const isPhoneUnique = await users.isPhoneUnique(model.id, model.phone);
        return Promise.resolve(isPhoneUnique ? PROMISE_OK : 'Пользователь с таким телефоном уже существует');
    }

    return Promise.resolve(PROMISE_OK);
};

export const crmUserEmailValidator = async (model: CrmUserModel) => {
    if (!model?.email && !model?.phone) {
        return Promise.resolve('Должен быть указан Email или Телефон');
    }

    if (model?.email) {
        const emailValidationRes = await emailValidator(model.email);
        if (emailValidationRes !== PROMISE_OK) {
            return Promise.resolve(emailValidationRes);
        }

        const isEmailUnique = await users.isEmailUnique(model.id, model.email);
        return Promise.resolve(isEmailUnique ? PROMISE_OK : 'Пользователь с таким Email уже существует');
    }

    return Promise.resolve(PROMISE_OK);
};

export const crmUserPassportNumberValidatorForRole = async (model: CrmUserModel, role: Role, required?: boolean) => {
    const passportNumberValidationRes = await crmUserPassportNumberValidator(model, required);
    if (passportNumberValidationRes !== PROMISE_OK) {
        return Promise.resolve(passportNumberValidationRes);
    }
    return crmUserPassportValidatorForRole(model, role);
};

export const crmUserPassportDateValidatorForRole = (model: CrmUserModel, role: Role, required?: boolean) => {
    const { passportDate } = model;
    if (!passportDate && required) {
        return requiredValidator(passportDate);
    }
    return crmUserPassportValidatorForRole(model, role);
};

export const crmUserPassportValidatorForRole = async (model: CrmUserModel, role: Role) => {
    const { id, passportNumber, passportDate } = model;
    if (passportNumber && passportDate && role) {
        const isPassportUnique = await users.isPassportUniqueForRole(id, passportNumber, passportDate, role);
        return Promise.resolve(isPassportUnique ? PROMISE_OK : 'Пользователь с таким паспортом уже существует');
    }
    return Promise.resolve(PROMISE_OK);
};

export const crmUserInnValidatorForRole = async (model: CrmUserModel, role: string, required?: boolean) => {
    const { id, inn } = model;
    const innValidationRes = await individualInnValidator(model, required);
    if (innValidationRes !== PROMISE_OK) {
        return Promise.resolve(innValidationRes);
    }
    if (inn) {
        const isInnUnique = await users.isInnUniqueForRole(id, inn, role);
        return Promise.resolve(isInnUnique ? PROMISE_OK : 'Пользователь с таким ИНН уже существует');
    }
    return Promise.resolve(PROMISE_OK);
};

export const crmUserSnilsValidator = (snils: string): Promise<string> =>
    Promise.resolve(
        !snils || snils11NumericRegex.test(snils) ? PROMISE_OK : 'СНИЛС должен состоять из 11 цифр'
    );
