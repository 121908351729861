import React from 'react';
import { Outlet } from 'react-router';
import { generatePath } from 'react-router-dom';
import vehicles from '../../../api/vehicles';
import util from '../../../common/util';
import {
    PATH_CP_VEHICLE,
    PATH_CP_VEHICLE_HISTORY,
    PATH_CP_VEHICLES,
    PATH_VARIABLE_VEHICLE_ID,
} from '../../../routerPaths';
import ContextError from '../../form/ContextError';
import { DwForm, useFieldValue } from '../../form/DwForm';
import DwFormTabs from '../../form/DwFormTabs';
import { FormConfig } from '../../form/formConfig';
import { VehicleField } from './vehicleField';
import {
    numberValidator,
    cubicCapacityValidator,
    tonnageValidator,
} from '../../../common/validation/vehicleValidators';

import type { VehicleModel } from '../../../model/vehicle/vehicleModel';
import { AttachmentModel } from '../../../model/attachment';
import { Option } from '../../control/option';
import { requiredListValidator } from '../../../common/validation/simpleValidators';

const formConfig = FormConfig.builder<VehicleModel>()
    .number(
        VehicleField.ID,
        (m) => m.id,
        (m, v) => (m.id = v)
    )
    .list<Option>(
        VehicleField.CLIENTS,
        (m) => m.clients,
        (m, v) => (m.clients = v),
        (m) => requiredListValidator(m.clients)
    )
    .requiredOption(
        VehicleField.TYPE,
        (m) => m.type,
        (m, v) => (m.type = v)
    )
    .requiredOption(
        VehicleField.OWNERSHIP,
        (m) => m.ownership,
        (m, v) => (m.ownership = v)
    )
    .requiredOption(
        VehicleField.CARCASS,
        (m) => m.carcass,
        (m, v) => (m.carcass = v)
    )
    .requiredOption(
        VehicleField.APPROVAL,
        (m) => m.approval,
        (m, v) => (m.approval = v)
    )
    .text(
        VehicleField.VIN,
        (m) => m.vin,
        (m, v) => (m.vin = v)
    )
    .requiredText(
        VehicleField.MODEL,
        (m) => m.model,
        (m, v) => (m.model = v)
    )
    .text(
        VehicleField.NUMBER,
        (m) => m.number,
        (m, v) => (m.number = v),
        (m) => numberValidator(m)
    )
    .boolean(
        VehicleField.ALIEN,
        (m) => m.alien,
        (m, v) => (m.alien = v)
    )
    .text(
        VehicleField.VOLUME,
        (m) => m.volume,
        (m, v) => (m.volume = v),
        (m) => cubicCapacityValidator(m)
    )
    .text(
        VehicleField.TONNAGE,
        (m) => m.tonnage,
        (m, v) => (m.tonnage = v),
        (m) => tonnageValidator(m)
    )
    .text(
        VehicleField.PTS_INFO,
        (m) => m.ptsInfo,
        (m, v) => (m.ptsInfo = v)
    )
    .dateTime(
        VehicleField.PTS_DATE,
        (m) => m.ptsDate,
        (m, v) => (m.ptsDate = v)
    )
    .text(
        VehicleField.OWNER,
        (m) => m.owner,
        (m, v) => (m.owner = v)
    )
    .text(
        VehicleField.COMMENT,
        (m) => m.comment,
        (m, v) => (m.comment = v)
    )
    .list<AttachmentModel>(
        VehicleField.ATTACHMENTS,
        (m) => m.attachments,
        (m, v) => (m.attachments = v)
    )
    .load((id) => vehicles.get(id))
    .submit(vehicles.save)
    .redirectUrl(PATH_CP_VEHICLES)
    .idPathVariableName(PATH_VARIABLE_VEHICLE_ID)
    .build();

const VehicleForm: React.FC = () => {
    return (
        <DwForm config={formConfig}>
            <VehicleLayout />
        </DwForm>
    );
};

/**
 *   Just a wrapper to access form context for further usage (e.g. adding field value to Tab name)
 */
const VehicleLayout: React.FC = () => {
    const vehicleId = useFieldValue(VehicleField.ID);
    const vehicleNumber = useFieldValue(VehicleField.NUMBER);
    const isNew: boolean = !vehicleId;

    return (
        <>
            <DwFormTabs
                items={[
                    {
                        name: isNew ? 'Новое транспортное средство' : vehicleNumber,
                        path: generatePath(PATH_CP_VEHICLE, {
                            [PATH_VARIABLE_VEHICLE_ID]: util.stringOrEmpty(vehicleId),
                        }),
                        end: true,
                    },
                    {
                        name: 'История',
                        path: generatePath(PATH_CP_VEHICLE_HISTORY, {
                            [PATH_VARIABLE_VEHICLE_ID]: util.stringOrEmpty(vehicleId),
                        }),
                        end: true,
                        disabled: isNew,
                    },
                ]}
            />
            <ContextError />
            <Outlet />
        </>
    );
};

export default VehicleForm;
